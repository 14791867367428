import { UserService } from './../../core/services/user.service';
import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {InService} from '../../core/services/in.service';
import * as moment from 'moment';
import {DispatchService} from '../../core/services/dispatch.service';
import {PickingService} from '../../core/services/picking.service';
import {ScreeningService} from '../../core/services/screening.service';
import {StockDataSourceService} from '../../core/data-sources/stock-data-source.service';
import { InspectionService } from '../../core/services/inspection.service';
import { CratesDataSourceService } from '../../core/data-sources/crates-data-source.service';
import { TenantInterface } from 'src/models/Tenant';
import { TenantService } from 'src/app/core/services/tenant.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  inValues = {
    invoices: '-',
    orders: '-',
    screening: '-'
  };
  pickingValues = {
    outOrders: '-',
    outOrdersInSeparation: '-',
    outOrdersWithPallets: '-'
  };
  stockValues = {
    inspection: '-',
    issuedInvoices: '-',
    stock: '-',
    exit: '-'
  };
  outValues = {
    dispatching: '-',
    finalized: '-',
    issuedInvoices: '-'
  };

  isLoading: boolean;
  tenant: TenantInterface;

  constructor(
    private router: Router,
    private inService: InService,
    private pickingService: PickingService,
    private screeningService: ScreeningService,
    private stockService: StockDataSourceService,
    private dispatchService: DispatchService,
    private inspectionService: InspectionService,
    private cratesService: CratesDataSourceService,
    private tenantService: TenantService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    Promise.all([
      this.getIn(),
      this.getStockAllProducts(),
      this.getOut(),
      this.getPicking(),
      this.getInspection(),
      this.getExit(),
      this.getStock()
    ]).then(() => { }).catch((err) => { console.log(err); })
      .finally(() => {
        this.isLoading = false;
        this.tenantService.get().then(res => {
          this.tenant = res;
        });
      });
  }

  getStockAllProducts(){
    const now = moment().format();
    const time30daysAgo = moment().subtract(30, 'days').startOf('day');
    return this.screeningService.getAllProducts(time30daysAgo, now).then(values =>  {
      this.inValues.screening = values.storage.length + values.divergent.length;
    });
  }

  getStock(){
    // In the dashboard, the information we need from this endpoint is just the count value, so to improve performance of the request
    // we are setting limit and offset to 1, as count value is not impacted by limit and offset 
    const stockParams = {
      limit: 1,
      offset: 1
    };
    return this.stockService.getStockCratesV2("STOCK", stockParams).then(values => {
      this.stockValues.stock = values.count;
    });
  }

  getExit() {
    return this.cratesService.getCratesFromExitPosition().then(values => {
      this.stockValues.exit = values.count.toString();
    });
  }

  getInspection() {
    const params = { limit: 5, offset: 0 };
    return this.inspectionService.getGoodsV3(params).then(response => {
      this.stockValues.inspection = response.count;
    });
  }
  getPicking(){
    return this.pickingService.getDocuments().then(values => {
      this.pickingValues.outOrders = values.outOrders.length;
      this.pickingValues.outOrdersInSeparation = values.outOrdersInSeparation.length;
      this.pickingValues.outOrdersWithPallets = values?.outOrdersWithPallets?.length ?? '-';
    });
  }
  getIn(){
    const now = moment();
    const time3daysAgo = moment().subtract(3, 'days').startOf('day');
    return this.inService.getDocuments(time3daysAgo, now).subscribe((values) => {
      this.inValues.invoices = values.invoices.length;
      this.inValues.orders = values.orders.filter((item) => {
        return moment(item.issuedAt).isSameOrAfter(time3daysAgo);
      }).length;
    });
  }
  getOut(){
    return this.dispatchService.getDocuments().then(values => {
      this.outValues.dispatching = values.dispatching.length;
      this.outValues.finalized = values.finalized.length;
      this.outValues.issuedInvoices = values.issuedInvoices.length;

    });
  }

  async toEnterDocsAndScreening() {
    await this.router.navigate(['/in']);
  }
  async toStock() {
    await this.router.navigate(['/stock']);
  }
  async toInspection() {
    await this.router.navigate(['/inspection']);
  }
  async toPicking() {
    await this.router.navigate(['/picking']);
  }
  async toDispatch() {
    await this.router.navigate(['/dispatch']);
  }
  async toScreening() {
    await this.router.navigate(['/screening']);
  }
  async toExit() {
    await this.router.navigate(['/exit'])
  }
}
