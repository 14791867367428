import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pallet } from '../stock-levels-modal.component';
import { CrateType, translateState } from 'src/models/Crate';
import { getCrateTypeName } from 'src/app/helpers/format-crate-type';

@Component({
  selector: 'app-crate-list-details',
  templateUrl: './crate-list-details.component.html',
  styleUrls: ['./crate-list-details.component.scss']
})
export class CrateListDetailsComponent implements OnInit, AfterViewChecked {
  @Input() pallets: Pallet[] = [];
  @Input() levels = [];
  @Input() palletToScrollTo: string;
  @Output() goToPalletDetails = new EventEmitter();
  CrateTypeEnum = CrateType;

  constructor() { }

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    const element = document.getElementById(this.palletToScrollTo);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
      this.palletToScrollTo = null;
    }
  }

  openPalletDetails(pallet: Pallet) {
    this.goToPalletDetails.emit(pallet);
  }

  formatType(type) {
    return getCrateTypeName(type);
  }

  translatePalletState(state) {
    return translateState(state);
  }

}
