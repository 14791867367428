<small>{{ label }}</small>
<div *ngIf="!isStatus">
  <p *ngIf="serial" class="{{valueColor}}">{{ value }}-{{ serial }}</p>
  <p *ngIf="!serial" class="{{valueColor}}">{{ value }}</p>
</div>
<!-- status icon section -->
<section *ngIf="isStatus">
  <div class="status-option" *ngIf="value > 0">
    <div class="status-icon online"></div>
    Ativo
  </div>
  <div class="status-option" *ngIf="value <= 0">
    <div class="status-icon"></div>
    Inativo
  </div>
</section>