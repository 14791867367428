import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ICrateData } from '../../../../../models/Report';
import { CrateStateEnum } from 'src/models/Crate';
import { formatGroundPositionName } from 'src/app/helpers/get-ground-positions';

@Component({
  selector: 'app-reports-table-modal-item',
  templateUrl: './reports-table-modal-item.component.html',
  styleUrls: ['./reports-table-modal-item.component.scss']
})
export class ReportsTableModalItemComponent implements OnInit {
  @Input() item: ICrateData;
  @Input() items: ICrateData[];
  @Input() report;
  @HostBinding('class.isOdd') @Input() isOdd;
  foundPosition = '---';
  expectedPosition = '';
  crateState = '';
  icon = '';
  iconBackgroundColor = '';
  expectedLevel = '---';
  foundLevel = '---';
  isMissingPallets: boolean;

  constructor() { }

  ngOnInit(): void {
    this.item.removePallet = false;
    // TODO: refactor this
    const possibleKeyName = [
      'found',
      'FOUND',
      'foundMisplaced',
      'FOUND_MISPLACED',
      'missing',
      'MISSING'
    ];

    let state = '';
    for (const name of possibleKeyName) {
      if (name in this.item) {
        state = name;
      }
    }
    // TODO: refactor this
    this.isMissingPallets = this.items.some(item => item.state === 'MISSING' || item?.inconsistency?.state === 'MISSING');
    this.handleStatusAndSetIcon(state);
    this.expectedLevel = this.item.groundPositionGroupLevelName ?? this.item.z + 'cm';
    this.foundLevel = this.item.groundPositionGroupLevelName ?? this.item.z + 'cm';
    this.expectedPosition = formatGroundPositionName(this.item);
    this.foundPosition = this.setFoundPosition(this.item);
    if (this.item.inconsistency && this.item.inconsistency.state === CrateStateEnum.FOUND_MISPLACED) {
      this.foundLevel = this.item.inconsistency?.z ? this.item.inconsistency.z + 'cm' : '---';
      return;
    } else if (this.item.inconsistency && this.item.inconsistency.state === CrateStateEnum.MISSING) {
      this.foundLevel = '---';
      return;
    }
  }

  setFoundPosition(data) {
    if (data?.inconsistency && data.inconsistency?.state === CrateStateEnum.FOUND_MISPLACED) {
      return formatGroundPositionName(data.inconsistency);
    }
    return this.item?.inconsistency?.state === CrateStateEnum.MISSING ? '---' : formatGroundPositionName(data);
  }

  handleStatusAndSetIcon(status: string) {
    if (status.toLowerCase().includes('misplaced')) status = 'found_misplaced';
    switch (status.toUpperCase()) {
      case CrateStateEnum.FOUND:
        this.icon = 'check';
        this.iconBackgroundColor = 'success';
        this.crateState = 'Encontrado';
        break;
      case CrateStateEnum.FOUND_MISPLACED:
        this.icon = 'times';
        this.iconBackgroundColor = 'warning';
        this.crateState = 'Encontrado em lugar indevido';
        break;
      case CrateStateEnum.MISSING:
        this.icon = 'times';
        this.iconBackgroundColor = 'alert';
        this.crateState = 'Perdido';
        break;
      default:
        this.icon = 'check';
        this.iconBackgroundColor = 'success';
        this.crateState = 'Encontrado';
        break;
    }
  }

  onCheckboxChange(pallet: ICrateData) {
    pallet.removePallet = !pallet.removePallet;
  }

}
