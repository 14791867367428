import { toExcelFormat } from "./format-position";

export function getGroundPositions(groundPosition) {
  const options = [];
  for (const item of groundPosition) {
    if (!options.filter(e => e.name === item.groupName).length && item.groupName !== null) {
      options.push({ name: item.groupName, groupId: item.groupId });
    }
  }
  const groundPositions = [...options];
  return groundPositions.sort((a, b) => {
    if (a > b) { return 1; }
    if (a < b) { return -1; }
    return 0;
  });
}

export function formatGroundPositionName(position, key = "groundPositionAlias"): string {
  if (position?.[key] && position?.[key] !== undefined) {
    return position?.[key];
  } else {
    return `--- (coordenada: ${toExcelFormat(position.x, position.y)})`
  }
}