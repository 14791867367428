import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TaskRequestSidenavService } from '../../../core/services/task-request-sidenav.service';
import { ActionService } from '../../../core/services/action.service';
import { MissingCratesService } from '../../../core/services/missing-crates.service';
import { MissingCrate } from 'src/models/Crate';

interface InventoryTableData {
  quantity: number;
  sku: string;
  tagRfid: string;
  total: number;
  id: string;
}

@Component({
  selector: 'app-unknown-address-inventory',
  templateUrl: './unknown-address-inventory.component.html',
  styleUrls: ['./unknown-address-inventory.component.scss']
})

export class UnknownAddressInventoryComponent implements OnInit, OnDestroy {
  @Input() drawer;
  displayedColumns = ['tagRfid', 'sku', 'quantity', 'total', 'actions'];
  columnDefinition = [
    { key: "tagRfid", text: "Tag Rfid", showTooltip: true },
    { key: "sku", text: "Código do produto" },
    { key: "quantity", text: "Quantidade", alignRight: true },
    { key: "total", text: "Total de Inventários", alignRight: true }
  ];
  selectedPallets: MissingCrate[] = [];
  usersHighlighted = [];
  selectOperatorError = false;
  palletsSubscription$ = new Subscription;
  inventoryTableData: InventoryTableData[] = [];

  constructor(
    private missingPalletsService: MissingCratesService,
    private sidenavService: TaskRequestSidenavService,
    private actionService: ActionService
  ) { }

  ngOnDestroy(): void {
    this.palletsSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.palletsSubscription$ = this.missingPalletsService.getMissingPallets().subscribe(value => {
      this.selectedPallets = value;
      this.formatArrayToTable();
    });
  }

  removeSelectedPallet(palletToRemove) {
    const index = this.selectedPallets.findIndex((item) => item.id === palletToRemove.id);
    this.selectedPallets.splice(index, 1);
    this.missingPalletsService.missingPallets$.next(this.selectedPallets);
  }

  formatArrayToTable() {
    this.inventoryTableData = this.selectedPallets.reduce((acc, pallet) => {
      const formatted = pallet.skuGroups.map(item => ({
        quantity: item.quantity,
        sku: item.sku,
        tagRfid: pallet.tagRfid,
        total: pallet.inventoryCount,
        id: pallet.id
      }));
      return acc.concat(formatted);
    }, []);
  }

  setUsers(users) {
    this.usersHighlighted = users;
  }

  close() {
    this.drawer.close();
    this.sidenavService.closeSidenav({ keepSelection: true });
  }

  async submit() {
    this.setOperatorLengthError();
    await this.missingPalletsInventory();
    this.drawer.close();
    this.sidenavService.closeSidenav(true);
  }

  async missingPalletsInventory() {
    const selectedPalletsTagsRfid = this.selectedPallets.map(pallet => pallet.tagRfid);
    const data = {
      type: "INVENTORY_BY_TAG_RFID",
      userIds: this.usersHighlighted,
      taskData: {
        deepSearch: true,
        tagRfid: selectedPalletsTagsRfid
      }
    };
    await this.actionService.inventoryByTagRfid(data);
  }

  setOperatorLengthError() {
    this.selectOperatorError = !this.usersHighlighted.length;
  }

}
