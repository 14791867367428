import { CratesDataSourceService } from './../../core/data-sources/crates-data-source.service';
import { PaginationService } from './../../core/services/pagination.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InspectionService } from '../../core/services/inspection.service';
import * as moment from 'moment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InspectionDetailsProductModalComponent } from '../../modals/inspection-details-product-modal/inspection-details-product-modal.component';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TaskRequestSidenavService } from '../../core/services/task-request-sidenav.service';
import { ActionService } from '../../core/services/action.service';
import { Destination } from './inspection-sidenav/inspection-sidenav.component';
import { ErrorService } from '../../core/services/error.service';
import { PhysicalInspectionItem } from 'src/models/PhysicalInspection';
import { formatGroundPositionName } from 'src/app/helpers/get-ground-positions';
import formatDate from 'src/app/helpers/format-date';
import getName from 'src/app/helpers/format-name';

export enum InspectionComponentStatusOptions {
  OK = 'OK',
  SURPLUS = 'SURPLUS',
  MISSING = 'MISSING',
  NONE = 'NONE'
}

enum PalletState {
  RECEIVED = 'RECEIVED',
  AWAITING_STORAGE = 'AWAITING_STORAGE'
}

enum TaskBasedOnDestiny {
  STORAGE = 'RELOCATE_STORAGE',
  STOCK_DIVERGENT = 'RELOCATE_DIVERGENT',
  QUALITY = 'RELOCATE_QUALITY',
}

export enum InspectionPageState {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  INITIAL = 'INITIAL'
}

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent implements OnInit, OnDestroy {
  InspectionPageState = InspectionPageState;
  PalletState = PalletState;
  filteredGoods: PhysicalInspectionItem[] = [];
  selectedGoodsTagRfids = new Set();
  searchTerm: string;
  dataSubscription: Subscription;
  actualHour;
  state: InspectionPageState = InspectionPageState.INITIAL;
  blockPaginatorButton = false;
  isLoadingModal = false;

  constructor(private router: Router,
    private inspectionService: InspectionService,
    public dialog: MatDialog,
    private sidenavService: TaskRequestSidenavService,
    private actionService: ActionService,
    private errorService: ErrorService,
    public paginationService: PaginationService,
    private cratesDataSourceService: CratesDataSourceService
  ) {}

  ngOnInit(): void {
    this.state = InspectionPageState.LOADING;
    this.dataSubscription = this.paginationService.items$.subscribe(items => {
      this.actualHour = moment().format('HH:mm');
      this.state = InspectionPageState.LOADED;
      this.filteredGoods = items;
    });
    this.inspectionService.init();
  }

  ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
  }

  allGoodsIsChecked() {
    const eligible = this.filteredGoods?.filter(g => g.palletState === PalletState.RECEIVED);
    if (eligible && eligible.length !== 0) {
      return eligible.length === this.selectedGoodsTagRfids.size;
    }
    return false;
  }

  selectAllGoods() {
    const eligible = this.filteredGoods?.filter(g => g.palletState === PalletState.RECEIVED);

    if (eligible.length === this.selectedGoodsTagRfids.size) {
      this.selectedGoodsTagRfids.clear();
    } else {
      for (const e of eligible) {
        this.selectedGoodsTagRfids.add(e.tagRfid);
      }
    }

  }

  selectGood(good) {
    if (!this.selectedGoodsTagRfids.has(good.tagRfid)) {
      this.selectedGoodsTagRfids.add(good.tagRfid);
    } else {
      this.selectedGoodsTagRfids.delete(good.tagRfid);
    }

  }

  async getInspectionList() {
    this.state = InspectionPageState.LOADING;
    try {
      await this.inspectionService.reload();
    } catch (e) {
      console.log(e);
    } finally {
      this.state = InspectionPageState.LOADED;
    }
  }

  openProductDetailsModal = async (item, event, page) => {
    try {
      this.isLoadingModal = true;
      const crateDetails = await this.cratesDataSourceService.getCrateById(item.crateId);
      this.isLoadingModal = false;
      const dialogRef: MatDialogRef<InspectionDetailsProductModalComponent> = this.dialog.open(InspectionDetailsProductModalComponent, {
        panelClass: 'details-modal',
        data: { item, page, crateDetails },
        autoFocus: false
      });
      dialogRef.afterClosed().pipe(first()).subscribe(res => {
        if (res === 'SUBMITTED') {
          this.update();
        }
      });
    } catch (err) {
      this.isLoadingModal = false;
      console.log(err);
    }
  }

  openTaskRequest(good) {

    const goods = [];
    let palletType = 'PALLET';
    let initialDestination = Destination.STORAGE;

    if (this.selectedGoodsTagRfids.has(good.tagRfid)) {
      const selected = this.filteredGoods.filter(g => this.selectedGoodsTagRfids.has(g.tagRfid));
      for (const s of selected) {
        for (const p of s.products) {
          goods.push({
            name: p.name,
            quantity: p.quantity,
            tagRfid: s.tagRfid
          });
        }
      }
      palletType = 'PALLET';
    } else {
      for (const p of good.products) {
        goods.push({
          name: p.name,
          quantity: p.quantity,
          tagRfid: good.tagRfid
        });
      }
      initialDestination = good.destination;
      palletType = good.palletType;
    }

    const data = {
      data: {
        goods,
        palletType,
        initialDestination
      },
      type: 'INSPECTION'
    };


    this.sidenavService.toggle(data).then(async (res: any) => {
      const { rfidTags } = res;
      const promises = [];
      for (const rfidTag of rfidTags) {
        const params = {
          type: TaskBasedOnDestiny[res.selectedDestination],
          userIds: res.userIds,
          tagRfid: rfidTag
        };
        if (!res.isCritical && res.userIds) {
          promises.push(this.sendTask(params));
        }
      }
      try {
        await Promise.all(promises);
      } catch (error) {
        this.errorService.openErrorSnackBar(error);
      }
      await this.update();

      if (res !== true) {
        this.selectedGoodsTagRfids.clear();
      }
      // const params = {
      //   type: TaskBasedOnDestiny[res.selectedDestination],
      //   userIds: res.userIds,
      //   tagRfid: good.tagRfid
      // };
      // if (res.isCritical) {
      //   await this.sendCriticalTask(params);
      //   await this.update();
      // } else if (!res.isCritical && res.userIds){
      //   await this.sendTask(params);
      //   await this.update();
      // }
      //


    });
  }

  async toStocking() {
    await this.router.navigate(['stock']);
  }

  async update() {
    await this.getInspectionList();
    this.actualHour = moment().format('HH:mm');
  }

  async sendTask(data) {
    await this.actionService.sendTask(data);
  }

  async sendCriticalTask(data) {
    await this.actionService.sendCriticalTask(data);
  }

  getButtonText(good) {
    let text = good.palletState === PalletState.RECEIVED ? 'ENVIAR' : 'SOLICITADO';
    if (this.selectedGoodsTagRfids.has(good.tagRfid)) {
      text += ` (${this.selectedGoodsTagRfids.size})`;
    }
    return text;
  }

  checkPalletType(palletType) {
    switch (palletType) {
      case 'PALLET':
        return 'pallet';
      case 'BIN':
        return 'bin';
      default:
        return '-';
    }
  }

  async getNextPage(event: boolean) {
    if (event) {
      this.blockPaginatorButton = true;
      this.state = InspectionPageState.LOADING;
      await this.inspectionService.getNextPage().finally(() => {
        this.blockPaginatorButton = false;
        this.state = InspectionPageState.LOADED;
      });
    }
  }

  async getPreviousPage(event: boolean) {
    if (event) {
      this.blockPaginatorButton = true;
      this.state = InspectionPageState.LOADING;
      await this.inspectionService.getPreviousPage().finally(() => {
        this.blockPaginatorButton = false;
        this.state = InspectionPageState.LOADED;
      });
    }
  }

  getGroundPositionAlias(position) {
    return formatGroundPositionName(position);
  }

  getUserName(user) {
    return getName(user);
  }

  formatTimestamp(timestamp: number) {
    return formatDate(timestamp);
  }

}
