<div class="lower-content">
  <div class="title" *ngIf="title">{{ title }}</div>
  <div class="warning title" *ngIf="warning">{{ warning }}</div>
  <div *ngIf="isLoading" class="loader">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>

  <table mat-table [dataSource]="tableData" multiTemplateDataRows class="table" *ngIf="!isLoading">
    <ng-container matColumnDef="action">
      <th mat-header-cell class="header" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <i *ngIf="element.enableExpansion && (!expandedElement || expandedElement.id !== element.id)" class="uil uil-angle-down"></i>
        <i *ngIf="element.enableExpansion && expandedElement?.id === element.id" class="uil uil-angle-up"></i>
      </td>
    </ng-container>

    <ng-container *ngFor="let column of columnsDefinition" [matColumnDef]="column.key">
      <th mat-header-cell class="header" *matHeaderCellDef>{{column.text}}</th>
      <td mat-cell *matCellDef="let element">{{element[column.key]}}</td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="expanded-detail"
          [@detailExpand]="element.enableExpansion && element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="left">
            <h4>Anterior à edição</h4>
            <app-material-generic-table
              [dataSource]="element.previousSkuGroups"
              [columnsDefinition]="productsTableColumnDefinition"
              [displayedColumns]="productsTableDisplayedColumns">
            </app-material-generic-table>
          </div>
          <mat-divider vertical></mat-divider>
          <div class="right">
            <h4>Após a edição</h4>
            <app-material-generic-table
              [dataSource]="element.currentSkuGroups"
              [columnsDefinition]="productsTableColumnDefinition"
              [displayedColumns]="productsTableDisplayedColumns">
            </app-material-generic-table>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row class="header-container" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="row" *matRowDef="let element; columns: displayedColumns; let i = dataIndex"
      [class.light-gray]="i % 2"
      [class.cursor-pointer]="element.enableExpansion" 
      [class.expanded-row]="expandedElement === element"
      (click)="element.enableExpansion && expandedElement = expandedElement === element ? null : element">
    </tr>

    <tr mat-row *matRowDef="let element; columns: ['expandedDetail']; let i = dataIndex"
      class="detail-row" [class.light-gray]="i % 2">
    </tr>
  </table>

  <div *ngIf="!tableData.length" class="no-item">
    Não existe histórico para este pallet
  </div>
</div>
