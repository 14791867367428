import { LayoutService } from './../../core/data-sources/layout.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GroundPositionService } from '../../core/services/ground-position.service';
import { columnToNumber, toExcelFormat } from '../../helpers/format-position';
import { GroundGroupPosition } from 'src/app/core/data-sources/ground-position-group-data-source.service';

export interface StockLevelsModalInterface {
  rfId: string;
  type: 'row' | 'column' | 'position';
  position: string;
  selectedTabIndex?: number;
  scrollTo?: string;
}
export interface GroupPosition {
  id: string;
  name: string;
  type: string;
  levelsEnabled: boolean;
}

export interface Level {
  id: string;
  name: string;
  order: number;
  reference: number;
  start: number;
  end: number;
  createdAt?: number;
  updatedAt?: number;
  tenantId?: string;
}
export interface Pallet {
  id: string;
  tagRfid: string;
  skuGroups: {
    packSize: number;
    quantity: number;
    sku: string;
    name: string;
  }[];
  state: string;
  type: string;
  x: number;
  y: number;
  z: number;
  position?: string;
  selected?: boolean;
  groundPositionGroupLevelName?: string;
}

@Component({
  selector: 'app-stock-levels-modal',
  templateUrl: './stock-levels-modal.component.html',
  styleUrls: ['./stock-levels-modal.component.scss']
})
export class StockLevelsModalComponent implements OnInit {
  @Input() data: StockLevelsModalInterface;
  @Output() changeToPallet = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() inventoryClicked = new EventEmitter();
  group: GroupPosition = {
    id: '',
    name: '',
    type: '',
    levelsEnabled: false
  };
  position: GroundGroupPosition;
  levels: Level[] = [];
  pallets: Pallet[] = [];
  tabLabel = '';
  occupationList = [];
  rowColumnPositions = [];
  rowColumnCoords = '';

  constructor(
    private groundPosition: GroundPositionService,
    private layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
    const { rfId } = this.data;
    this.setTabLabelName();
    if (this.data.type === 'position') {
      this.groundPosition.getDetails(rfId).then(res => {
        const { position } = res;
        const { group } = position;
        position.coords = `${toExcelFormat(position.x, position.y)} (x:${position.x} y:${position.y})`;
        this.position = position;

        if (group) {
          this.group.id = group.id;
          this.group.name = group.name;
          this.group.type = group.type;
          this.group.levelsEnabled = group.levelsEnabled;
        }
        const levels = res?.levels.sort((a, b) => b.order - a.order) ?? [];
        this.levels = levels;
        this.pallets = res?.pallets.filter(p => p.type !== 'ABSTRACT') ?? [];
        this.getCellPositionCount(position.x, position.y);
      });
    } else {
      this.getRowOrColumnPositionCount(this.data.type);
    }
  }

  getRowOrColumnPositionCount(type: 'row' | 'column') {
    if (type === 'row') {
      const y = Number(this.data.position) - 1;
      this.rowColumnCoords = `y: ${y}`;
      this.getRowOrColumnData(null, y);
    } else {
      const x = columnToNumber(this.data.position);
      this.rowColumnCoords = `x: ${x}`;
      this.getRowOrColumnData(x, null);
    }
  }

  async getRowOrColumnData(x: number | null, y: number | null) {
    await this.layoutService.getPositionCount(x, y).then(list => {
      this.occupationList = list;
    });
    if (this.occupationList.length) {
      await this.groundPosition.getMapGroundPositions(x, y).then(res => {
        this.pallets = this.setRowOrColumnsProperties(res, 'pallets');
        this.rowColumnPositions = this.setRowOrColumnsProperties(res, 'position');
      });
    }
  }

  async getCellPositionCount(x: number, y: number) {
    await this.layoutService.getPositionCount(x, y).then(list => {
      this.occupationList = list;
    });
  }

  handleCloseModalEvent(event: boolean) {
    if (event) {
      this.closeModal.emit(true);
    }
  }

  setRowOrColumnsProperties(arr, propertyName: 'levels' | 'pallets' | 'position') {
    return arr.reduce((acc, obj) => {
      return acc.concat(obj[propertyName]);
    }, []);
  }

  setTabLabelName() {
    switch (this.data.type) {
      case 'column':
        this.tabLabel = 'Detalhes da Coluna';
        break;
      case 'row':
        this.tabLabel = 'Detalhes da Linha';
        break;
      default:
        this.tabLabel = 'Detalhes da Posição';
        break;
    }
  }

  openPalletDetails(pallet: Pallet) {
    this.changeToPallet.emit(pallet);
  }

  handleInventoryClick(position) {
    this.inventoryClicked.emit(position);
  }

}
