<div class="wrapper">
  <div class="upper-content">
    <div class="first-column">
      <app-info-oc-or-nfe label="Tag RFID" [value]="crateDetails.crate.tagRfid"
        *ngIf="crateDetails.crate.type !== CrateTypeEnum.REMAINS">
      </app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Tag RFID" [value]="'N/A'"
        *ngIf="isExit && crateDetails.crate.type === CrateTypeEnum.REMAINS">
      </app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Estado (crateState)" [value]="translateState(crateDetails.crate.state)"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Posição" [value]="getPosition(crateDetails.position)"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Tipo" [value]="getCrateType(crateDetails.crate.type)" *ngIf="isExit"></app-info-oc-or-nfe>
    </div>
    <div class="second-column">
      <app-info-oc-or-nfe label="X" [value]="crateDetails.crate.x"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Y" [value]="crateDetails.crate.y"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Z" [value]="crateDetails.crate.z"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Nível" [value]="level"></app-info-oc-or-nfe>
    </div>
    <div class="third-column" *ngIf="!isExit">
      <button
        mat-raised-button (click)="relocateClick()" [disabled]="cantRelocate()">
        Realocar
      </button>
    </div>
  </div>
  <div class="lower-content">
    <table mat-table [dataSource]="dataSource" class="table">

      <ng-container matColumnDef="name">
        <th mat-header-cell class="header" *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="packSize">
        <th mat-header-cell class="header" *matHeaderCellDef>Tamanho do Pacote</th>
        <td mat-cell *matCellDef="let element">{{element.packSize}}</td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell class="header" *matHeaderCellDef>Quantidade</th>
        <td mat-cell *matCellDef="let element">{{element.quantity}}</td>
      </ng-container>

      <ng-container matColumnDef="sku">
        <th mat-header-cell class="header" *matHeaderCellDef>Código SKU</th>
        <td mat-cell *matCellDef="let element">{{element.sku}}</td>
      </ng-container>

      <tr mat-header-row class="header-container" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="item-container" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div *ngIf="!dataSource.length" class="no-item">
      Não existem produtos neste pallet
    </div>
  </div>
</div>
