import { StockDataSourceService } from 'src/app/core/data-sources/stock-data-source.service';
import { SnackBarService } from './../../../core/services/snack-bar.service';
import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { GroupPosition, Level, Pallet } from '../stock-levels-modal.component';
import { GroundType } from 'src/app/pages/map/warehouse-map-task-position-view/ground-cell';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { ActionService } from 'src/app/core/services/action.service';
import { ActionsEnum } from 'src/app/core/data-sources/action-data-source.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { setOccupationChartDataset } from 'src/app/helpers/chart-helper';
import { GroundGroupPosition } from 'src/app/core/data-sources/ground-position-group-data-source.service';

@Component({
  selector: 'app-position-details',
  templateUrl: './position-details.component.html',
  styleUrls: ['./position-details.component.scss']
})
export class PositionDetailsComponent implements OnChanges {
  @Output() closeStockLevelsModal = new EventEmitter<boolean>();
  @Output() onInventoryClick = new EventEmitter<boolean>();
  @Input() group: GroupPosition = {
    id: '',
    name: '',
    type: '',
    levelsEnabled: false
  };
  @Input() position: GroundGroupPosition;
  @Input() occupationList = [];
  @Input() levels: Level[] = [];
  @Input() pallets: Pallet[];
  @ViewChild('notification', { static: true }) notificationTmpl;
  groundTypes = GroundType;
  notificationData: { type: string; title: string; message: string; };
  datasets = [{
    data: [10, 10],
    backgroundColor: ['#92B531', '#E8EBEF']
  }];
  chartLabels = ['Posições Ocupadas', 'Posições Livres'];
  tooltipMessage: string | null = null;

  constructor(
    private dialog: MatDialog,
    private actionService: ActionService,
    private errorService: ErrorService,
    private snackBarService: SnackBarService,
    private stockDataSourceService: StockDataSourceService
  ) { }

  ngOnChanges(): void {
    if (this.occupationList.length) this.datasets[0].data = setOccupationChartDataset(this.occupationList);
    this.tooltipMessage = this.group.id ? null : 'Posições sem grupo não podem ser inventariadas. Atribua a posição a um grupo e tente novamente.';
  }

  removePallets() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'confirm-modal',
      autoFocus: false,
      data: {
        label: `Você tem certeza que deseja remover o(s) ${this.pallets.length} pallet(s) da posição ${this.position.coords}?`,
        confirmBtnClass: 'alert'
      }
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const rfids: string[] = [];
        rfids.push(this.position.rfId);
        try {
          await this.actionService.removePalletsFromExitPosition({
            type: ActionsEnum.EXIT_BY_GROUND_POSITION,
            data: {
              rfids: rfids
            }
          });
          this.notificationData = {
            type: 'success',
            title: 'Pallet(s) removido(s) com sucesso!',
            message: `O(s) ${this.pallets.length} pallet(s) da posição ${this.position.coords} foram removidos com sucesso.`
          };
          const notificationId = '99';
          this.snackBarService.showFeedback(this.notificationTmpl, this.notificationData, notificationId);
          setTimeout(() => {
            this.snackBarService.hideNotification(notificationId);
          }, 5000);
          this.stockDataSourceService.reloadMap$.next(true);
          this.closeStockLevelsModal.emit(true);
        } catch (err) {
          if (err.error.type === "GROUND_POSITION_TYPE_INVALID") {
            const message = `A posição escolhida não é uma posição de Saída`;
            this.errorService.openErrorSnackBarWithCustomMessage(message);
            return;
          }
          this.errorService.openErrorSnackBar(err);
        }
      }
    });
  }

  inventory(position) {
    this.onInventoryClick.emit(position);
  }

}
