import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock-footer',
  templateUrl: './stock-footer.component.html',
  styleUrls: ['./stock-footer.component.scss']
})
export class StockFooterComponent implements OnInit {
  @Input() value = 0;

  constructor() { }

  ngOnInit(): void {
    
  }

}
