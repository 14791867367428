<ng-container [formGroup]="form">
  <app-table-header-column name="createdAt">
    <span content>
      Data de criação
      <app-filter-button [column]="'createdAt'" [menu]="createdAtMenu" [appliedFilters]="appliedFilters"
        #createdAtFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #createdAtMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Ordenar por data de criação</h4>
      <button (click)="sort('createdAt', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('createdAt', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'ASC'"></i>
      </button>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters(createdAtFilterBtn.menuTrigger)">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(createdAtFilterBtn.menuTrigger)">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="name">
    <span content>
      Nome
      <app-filter-button [column]="'name'" [menu]="nameMenu" [appliedFilters]="appliedFilters"
        #nameFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #nameMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por nome</h4>
      <button (click)="sort('name', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['name'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('name', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['name'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Nome" formControlName="name" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters(nameFilterBtn.menuTrigger)">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(nameFilterBtn.menuTrigger)">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="description">
    <span content>
      Descrição
      <app-filter-button [column]="'description'" [menu]="descriptionMenu" [appliedFilters]="appliedFilters"
        #descriptionFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #descriptionMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por descrição</h4>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Descrição" formControlName="description" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters(descriptionFilterBtn.menuTrigger)">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(descriptionFilterBtn.menuTrigger)">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="sku">
    <span content>
      SKU
      <app-filter-button [column]="'sku'" [menu]="skuMenu" [appliedFilters]="appliedFilters"
        #skuFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #skuMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por SKU</h4>
      <button (click)="sort('sku', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['sku'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('sku', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['sku'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <input matInput placeholder="SKU" formControlName="sku" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters(skuFilterBtn.menuTrigger)">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(skuFilterBtn.menuTrigger)">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="weight" size="small">
    <span content>
      Peso
      <app-filter-button [column]="'weight'" [menu]="weightMenu" [appliedFilters]="appliedFilters"
        #weightFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #weightMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por peso(kg)</h4>
      <mat-form-field appearance="outline">
        <input matInput formControlName="minWeight" type="number" placeholder="Peso mínimo" autocomplete="off">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput formControlName="maxWeight" type="number" placeholder="Peso máximo" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters(weightFilterBtn.menuTrigger)">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(weightFilterBtn.menuTrigger)">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="width" size="small">
    <span content>
      Comprimento
      <app-filter-button [column]="'width'" [menu]="widthMenu" [appliedFilters]="appliedFilters"
        #widthFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #widthMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por comprimento(m)</h4>
      <mat-form-field appearance="outline">
        <input matInput formControlName="minWidth" type="number" placeholder="Comprimento mínimo" autocomplete="off">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput formControlName="maxWidth" type="number" placeholder="Comprimento máximo" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters(widthFilterBtn.menuTrigger)">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(widthFilterBtn.menuTrigger)">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="height" size="small">
    <span content>
      Altura
      <app-filter-button [column]="'height'" [menu]="heightMenu" [appliedFilters]="appliedFilters"
        #heightFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #heightMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por altura(m)</h4>
      <mat-form-field appearance="outline">
        <input matInput formControlName="minHeight" type="number" placeholder="Altura mínima" autocomplete="off">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput formControlName="maxHeight" type="number" placeholder="Altura máxima" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters(heightFilterBtn.menuTrigger)">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(heightFilterBtn.menuTrigger)">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="depth" size="small">
    <span content>
      Profundidade
      <app-filter-button [column]="'depth'" [menu]="depthMenu" [appliedFilters]="appliedFilters"
        #depthFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #depthMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por profundidade(m)</h4>
      <mat-form-field appearance="outline">
        <input matInput formControlName="minDepth" type="number" placeholder="Profundidade mínima" autocomplete="off">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput formControlName="maxDepth" type="number" placeholder="Profundidade máxima" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters(depthFilterBtn.menuTrigger)">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(depthFilterBtn.menuTrigger)">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="delete-btn" size="button">
    <span content></span>
  </app-table-header-column>
</ng-container>