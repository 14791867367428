import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Crate, MissingCrate } from '../../../models/Crate';
import { appendGroundPositionTypeParams } from '../../helpers/append-query-params';

interface MissingCratesAPIResponse {
  count: number;
  crates: MissingCrate[];
}

interface ProductCountResponse {
  allProducts: {
    products: Products[];
    total: number;
  }
  target?: {
    products: Products[];
    total: number;
  }
}

interface Products {
  sku: string;
  quantity: number;
  name: string;
}

export interface ExitAPIResponse {
  count: number;
  crates: Crate[];
}

@Injectable({
  providedIn: 'root'
})
export class CratesDataSourceService {

  constructor(private http: HttpClient) { }

  getMissingCrates(params?) {
    return this.http.get<MissingCratesAPIResponse>(environment.baseUrl + '/api/v2/admin-desktop/crates/missing', { params: params }).toPromise<MissingCratesAPIResponse>();
  }

  getProductsCount(groundPositionType: string, params?) {
    const queryParams = appendGroundPositionTypeParams(params, groundPositionType);
    return this.http.get<ProductCountResponse>(environment.baseUrl + '/api/v2/admin-desktop/crates/products-count', { params: queryParams }).toPromise<ProductCountResponse>();
  }

  getCratesFromExitPosition(params?) {
    return this.http.get<ExitAPIResponse>(environment.baseUrl + '/api/v2/admin-desktop/crates/exit', { params: params }).toPromise();
  }

  getCrateById(id: string) {
    return this.http.get(environment.baseUrl + `/api/v2/admin-desktop/crates/${id}`).toPromise();
  }
}
