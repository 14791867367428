<app-table-footer-column name="total" size="small">
  <span content>Total</span>
</app-table-footer-column>
<app-table-footer-column size="spacer">
  <span content></span>
</app-table-footer-column>
<app-table-footer-column name="quantity" size="smallest">
  <span content>{{value | number : '1.0-0' : 'pt-BR'}}</span>
</app-table-footer-column>
<app-table-footer-column size="small">
  <span content></span>
</app-table-footer-column>
