import centimetersToMeters from '../../../helpers/centimeters-to-meters';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import formatDate from '../../../helpers/format-date';
import { Crate } from '../../../../models/Crate';
import { toExcelFormat } from '../../../helpers/format-position';
import { getCrateTypeName } from '../../../helpers/format-crate-type';

@Component({
  selector: 'app-exit-table',
  templateUrl: './exit-table.component.html',
  styleUrls: ['./exit-table.component.scss']
})
export class ExitTableComponent implements OnInit {
  @Input() crates: Crate[];
  @Output() clicked = new EventEmitter();
  @Output() onFilterValuesChange = new EventEmitter();
  appliedFilters = {};
  sortParams = {
    orderBy: 'lastMovedAt',
    sortBy: 'DESC'
  };

  constructor() { }

  ngOnInit(): void {
  }
  
  itemClicked(crate) {
    this.clicked.emit({ crate: crate });
  }

  formatDate(date) {
    return formatDate(date);
  }

  toExcelFormat(x, y) {
    return toExcelFormat(x, y);
  }

  centimetersToMeters(z) {
    return centimetersToMeters(z);
  }

  getCrateType(type) {
    return getCrateTypeName(type);
  }

  onFiltersApply(params: { sortParams, filterParams }) {
    this.appliedFilters = params?.filterParams;
    this.sortParams = this.setSortParams(params?.sortParams);
    this.onFilterValuesChange.emit(params);
  }

  setSortParams(sort) {
    if (!sort || !sort.sortBy) {
      return;
    }
    return sort;
  }

}
