<div class="prev-next">
  <h1>Processos</h1>
  <!-- <div class="align">
    <app-prev-next-button (click)="toEnterDocsAndScreening()" [direction]="'right'" [label]="'documentos de entrada'"></app-prev-next-button>
  </div> -->
</div>
<div class="content">
  <app-dashboard-groups title="Recebimento" color="in" [disabled]="!tenant?.process?.in" [class.disabled]="!tenant?.process?.in">
    <app-dashboard-item
      [disabled]="!tenant?.process?.in"
      color="in"
      number="{{tenant?.process?.in ? inValues.orders : '-'}}"
      label="Ordens de Compra"
      [isLoading]="isLoading"
      (click)="toEnterDocsAndScreening()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      [disabled]="!tenant?.process?.in"
      color="in"
      number="{{tenant?.process?.in ? inValues.invoices : '-'}}"
      label="Notas Fiscais"
      [isLoading]="isLoading"
      (click)="toEnterDocsAndScreening()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      [disabled]="!tenant?.process?.in"
      color="in"
      number="{{tenant?.process?.in ? inValues.screening : '-'}}"
      label="Triagem"
      [isLoading]="isLoading"
      (click)="toScreening()"
    >
    </app-dashboard-item>

  </app-dashboard-groups>
  <app-dashboard-groups title="Armazém" color="primary-1" [disabled]="!tenant?.process?.stock" [class.disabled]="!tenant?.process?.stock">

    <app-dashboard-item
      [disabled]="!tenant?.process?.stock"
      color="primary-1"
      number="{{tenant?.process?.stock ? stockValues.inspection : '-'}}"
      label="Conferência Física"
      [isLoading]="isLoading"
      (click)="toInspection()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      [disabled]="!tenant?.process?.stock"
      color="primary-1"
      number="{{tenant?.process?.stock ? stockValues.stock : '-'}}"
      label="Estoque"
      [isLoading]="isLoading"
      (click)="toStock()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      [disabled]="!tenant?.process?.stock"
      color="primary-1"
      number="{{tenant?.process?.stock ? stockValues.exit : '-'}}"
      label="Saída"
      [isLoading]="isLoading"
      (click)="toExit()"
    >
    </app-dashboard-item>

  </app-dashboard-groups>
  <app-dashboard-groups title="Separação" color="picking" [disabled]="!tenant?.process?.packing" [class.disabled]="!tenant?.process?.packing">

    <app-dashboard-item
      [disabled]="!tenant?.process?.packing"
      color="picking"
      number="{{tenant?.process?.packing ? pickingValues.outOrders : '-'}}"
      label="Ordens de Venda"
      [isLoading]="isLoading"
      (click)="toPicking()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      [disabled]="!tenant?.process?.packing"
      color="picking"
      number="{{tenant?.process?.packing ? pickingValues?.outOrdersWithPallets : '-'}}"
      label="Ordens Reservadas"
      [isLoading]="isLoading"
      (click)="toPicking()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      [disabled]="!tenant?.process?.packing"
      color="picking"
      number="{{tenant?.process?.packing ? pickingValues.outOrdersInSeparation : '-'}}"
      label="Em Separação"
      [isLoading]="isLoading"
      (click)="toPicking()"
    >
    </app-dashboard-item>

  </app-dashboard-groups>
  <app-dashboard-groups title="Expedição" color="out" [disabled]="!tenant?.process?.out" [class.disabled]="!tenant?.process?.out">

    <app-dashboard-item
      [disabled]="!tenant?.process?.out"
      color="out"
      number="{{tenant?.process?.out ? outValues.issuedInvoices : '-'}}"
      label="Notas Expedidas"
      [isLoading]="isLoading"
      (click)="toDispatch()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      [disabled]="!tenant?.process?.out"
      color="out"
      number="{{tenant?.process?.out ? outValues.dispatching : '-'}}"
      label="Carregando"
      [isLoading]="isLoading"
      (click)="toDispatch()"
    >
    </app-dashboard-item>

    <app-dashboard-item
      [disabled]="!tenant?.process?.out"
      color="out"
      number="{{tenant?.process?.out ? outValues.finalized : '-'}}"
      label="Finalizados"
      [isLoading]="isLoading"
      (click)="toDispatch()"
    >
    </app-dashboard-item>

  </app-dashboard-groups>
</div>
