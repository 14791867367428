import { Injectable } from '@angular/core';
import { CratesDataSourceService } from '../data-sources/crates-data-source.service';
import { PaginationService } from './pagination.service';
import { StockFilterParams, StockSortParams } from './stock.service';
import { SkuGroups } from '../../../models/History';

@Injectable({
  providedIn: 'root'
})
export class ExitService {

  constructor(
    private dataSource: CratesDataSourceService,
    private paginationService: PaginationService
  ) { }

  async init() {
    return this.getPage(1);
  }

  async setPageSize(pageSize: number) {
    this.paginationService.pageSize = pageSize;
    this.paginationService.pageCount = Math.ceil(this.paginationService.totalItems / this.paginationService.pageSize);
    this.paginationService.$pageSize.next(this.paginationService.pageSize);
    await this.getPage(1);
  }

  async reload(filter: StockFilterParams = null, sortParams?: StockSortParams) {
    return await this.getPage(this.paginationService.currentPage, filter, sortParams);
  }

  async getNextPage(filter: StockFilterParams = null, sortParams?: StockSortParams) {
    if (this.paginationService.currentPage < this.paginationService.pageCount) {
      return await this.getPage(this.paginationService.currentPage + 1, filter, sortParams);
    } else {
      throw Error('???');
    }
  }

  async getPreviousPage(filter: StockFilterParams = null, sortParams?: StockSortParams) {
    if (this.paginationService.currentPage > 1) {
      return await this.getPage(this.paginationService.currentPage - 1, filter, sortParams);
    } else {
      throw Error('???');
    }
  }

  private async getPage(page: number, filter: StockFilterParams = {}, sortParams?: StockSortParams) {
    this.paginationService.currentPage = page;
    const params = {
      limit: this.paginationService.pageSize,
      offset: (this.paginationService.currentPage - 1) * this.paginationService.pageSize
    };
    Object.assign(params, filter);
    Object.assign(params, sortParams);
    const response = await this.dataSource.getCratesFromExitPosition(params);
    this.paginationService.totalItems = response.count;
    this.paginationService.pageCount = Math.ceil(this.paginationService.totalItems / this.paginationService.pageSize);
    this.paginationService.$items.next(response.crates);
    this.paginationService.$pageCount.next(this.paginationService.pageCount);
    this.paginationService.$totalItems.next(this.paginationService.totalItems);
    this.paginationService.$currentPage.next(this.paginationService.currentPage);
    this.paginationService.$atLastPage.next(this.paginationService.pageCount === 0 || this.paginationService.currentPage === this.paginationService.pageCount);
    this.paginationService.$atFirstPage.next(this.paginationService.currentPage === 1);
  }
}
