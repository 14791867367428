<div class="row">
  <section class="left-side">
    <div class="position">
      <app-info-oc-or-nfe label="RfId do chão" [value]="position?.rfId"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Coordenadas" [value]="position?.coords"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Apelido" [value]="position?.alias || '---'"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="tipo do chão" [value]="position?.type"></app-info-oc-or-nfe>
      <div class="buttons" *ngIf="(group.type || position.type) === groundTypes.EXIT && pallets.length">
        <button mat-stroked-button class="alert" (click)="removePallets()">Limpar Pallets</button>
      </div>
    </div>
    <div class="group">
      <app-info-oc-or-nfe label="grupo" [value]="group.name || '---'"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="tipo do grupo" [value]="group.type || '---'"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="usando níveis do grupo" [value]="group.levelsEnabled ? 'sim' : 'não'"></app-info-oc-or-nfe>
      <button mat-raised-button class="primary-1" (click)="inventory(position)"
        [disabled]="!group.id" [matTooltip]="tooltipMessage" matTooltipClass="line-break-tooltip">
        inventariar
      </button>
    </div>
  </section>
  <mat-divider vertical></mat-divider>
  <div class="right-side">
    <app-doughnut-chart [datasets]="datasets" [labels]="chartLabels"></app-doughnut-chart>
    <app-common-list [list]="occupationList"></app-common-list>
  </div> 
</div> 
<div class="levels">
  <div class="table">
    <div class="header">
      <div class="column">
        nível
      </div>
      <div class="column">
        order
      </div>
      <div class="column">
        começo
      </div>
      <div class="column">
        referência
      </div>
      <div class="column">
        fim
      </div>
    </div>

    <div class="item"
         *ngFor="let level of levels">

      <div class="column">
        {{ level.name }}
      </div>
      <div class="column">
        {{ level.order }}
      </div>
      <div class="column">
        {{ level.start }} cm
      </div>
      <div class="column">
        {{ level.reference }} cm
      </div>
      <div class="column">
        {{ level.end }} cm
      </div>
    </div>
  </div>
</div>

<ng-template #notification>
  <app-notification [data]="notificationData" icon="check-circle"></app-notification>
</ng-template>
