<app-side-color-card [color]="color" [disabled]="disabled">
  <div class="content" content  [ngClass]="color" [class.disabled]="disabled">
    <div>
      <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
    </div>
    <div class="number" *ngIf="!isLoading">
      {{number}}
    </div>
    <div class="label">
      {{label}}
    </div>
  </div>
</app-side-color-card>
