import { Injectable } from '@angular/core';
import { PaginationService } from './pagination.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { CratesDataSourceService } from '../data-sources/crates-data-source.service';
import { MissingCrate } from 'src/models/Crate';

export interface MissingCrateSortParams {
  sortBy: "ASC" | "DESC",
  orderBy: "activeInventoryCount" | "createdAt" | "inventoryCount" | "lastMovedAt" | "sku" | "quantity" | "tagRfid" | "updatedAt" | "alias" | "section"
}

interface FilterParams {
  startDate?: Date,
  endDate?: Date,
  quantity?: number,
  inventoryCount?: number,
  maxActiveInventoryCount?: number,
  minActiveInventoryCount?: number,
  sku?: string,
  tagRfid?: string;
  section?: string;
  alias?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MissingCratesService {
  public missingPallets$: BehaviorSubject<MissingCrate[]> = new BehaviorSubject<MissingCrate[]>([]);

  constructor(
    private dataSource: CratesDataSourceService,
    private paginationService: PaginationService
  ) { }

  getMissingPallets(): Observable<MissingCrate[]> {
    return this.missingPallets$.asObservable();
  }

  async init(filter: FilterParams = null) {
    return this.getPage(1, filter);
  }

  async reload(filter: FilterParams = null, sortParams: MissingCrateSortParams = null) {
    return await this.getPage(this.paginationService.currentPage, filter, sortParams);
  }

  private async getPage(page: number, filter: FilterParams = {}, sortParams: MissingCrateSortParams = null) {
    this.paginationService.currentPage = page;

    const params = {
      limit: this.paginationService.pageSize,
      offset: (this.paginationService.currentPage - 1) * this.paginationService.pageSize
    };
    Object.assign(params, sortParams);
    Object.assign(params, filter);
    const response = await this.dataSource.getMissingCrates(params);
    this.paginationService.totalItems = response.count;
    this.paginationService.pageCount = Math.ceil(this.paginationService.totalItems / this.paginationService.pageSize);
    this.paginationService.$items.next(response.crates);
    this.paginationService.$pageCount.next(this.paginationService.pageCount);
    this.paginationService.$totalItems.next(this.paginationService.totalItems);
    this.paginationService.$currentPage.next(this.paginationService.currentPage);
    this.paginationService.$atLastPage.next(this.paginationService.pageCount === 0 || this.paginationService.currentPage === this.paginationService.pageCount);
    this.paginationService.$atFirstPage.next(this.paginationService.currentPage === 1);
  }

  async getNextPage(filter: FilterParams = null, sortParams: MissingCrateSortParams) {
    if (this.paginationService.currentPage < this.paginationService.pageCount) {
      return await this.getPage(this.paginationService.currentPage + 1, filter, sortParams);
    } else {
      throw Error('???');
    }
  }

  async getPreviousPage(filter: FilterParams = null, sortParams: MissingCrateSortParams) {
    if (this.paginationService.currentPage > 1) {
      return await this.getPage(this.paginationService.currentPage - 1, filter, sortParams);
    } else {
      throw Error('???');
    }
  }

}
