import { CratesDataSourceService } from './../../core/data-sources/crates-data-source.service';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {CommentDataSourceService} from '../../core/data-sources/comment-data-source.service';

enum CommentAt{
  INVOICE= 'INVOICE',
  ENTRY= 'ENTRY'
}

@Component({
  selector: 'app-inspection-details-product-modal',
  templateUrl: './inspection-details-product-modal.component.html',
  styleUrls: ['./inspection-details-product-modal.component.scss']
})
export class InspectionDetailsProductModalComponent implements OnInit {
  selected = new FormControl(0);
  isCommentChange = false;
  isDestinyChange = false;
  highlightedProducts = [];
  commentAt: CommentAt = CommentAt.INVOICE;
  loadHistoryTab = false;
  loadProductTab = false;
  products: {
    name: string;
    sku: string;
  }[];

  constructor(public dialogRef: MatDialogRef<InspectionDetailsProductModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private commentDataSource: CommentDataSourceService,
              private cratesDataSourceService: CratesDataSourceService) { }

  ngOnInit(): void {
    this.selected.setValue(this.data.page);
    this.parseProduct();
    this.highlightedProducts = this.data.item.products.map(p => p.sku);

    this.commentAt = this.data.item.inType === 'WITHOUT_INVOICE' ? CommentAt.ENTRY : CommentAt.INVOICE;

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.close();
      }
    });

    this.dialogRef.backdropClick().subscribe(event => {
      this.close();
    });
  }

  close(event?) {
    if (this.isCommentChange || this.isDestinyChange || event === 'SUBMITTED') {
      this.dialogRef.close('SUBMITTED');
    } else {
      this.dialogRef.close();
    }
  }

  async submitComment(event) {
    try {
      switch (this.commentAt) {
        case CommentAt.INVOICE:
          await this.commentDataSource.postInspectionComment(this.data.item.invoicesId[0], event.comment, 'CHECKING', this.data.item.products[0].sku);
          break;
        case CommentAt.ENTRY:
          await this.commentDataSource.postEntryComment(this.data.item.entryId, event.comment, 'RECEIVED', this.data.item.products[0].sku);
      }

    } catch (e) {
      console.log(e);
    } finally {
      await this.update();
      this.isCommentChange = true;
    }
  }

  async deleteComment(id) {
    try {
      switch (this.commentAt) {
        case CommentAt.INVOICE:
          await this.commentDataSource.deleteInvoiceComment(this.data.item.invoicesId[0], id);
          break;
        case CommentAt.ENTRY:
          await this.commentDataSource.deleteEntryComment(this.data.item.entryId, id);
      }
    } catch (e) {
      console.log(e);
    } finally {
      await this.update();
      this.isCommentChange = true;
    }
  }

  async update() {
    this.data.crateDetails = await this.cratesDataSourceService.getCrateById(this.data.item.crateId);
  }

  onChangeDestination() {
    this.isDestinyChange = true;
  }

  parseProduct() {
    this.products = this.data.item.products.map(p => {
      return {
        name: p.name,
        sku: p.sku
      };
    });
  }

  onSelectedIndexChange(index: number) {
    this.selected.setValue(index);
    this.loadHistoryTab = index === 1;
    this.loadProductTab = index === 2;
  }
}
