import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit {
  @Input() column = '';
  @Input() menu: MatMenu;
  @Input() appliedFilters = {};
  @Input() sortColumn = '';
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;

  constructor() { }

  ngOnInit(): void {
  }

}
