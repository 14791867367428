import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { appendParams } from '../../helpers/append-query-params';
import { GroundPositionType } from '../../pages/warehouse-map/warehouse-map-table/warehouse-map-table.component';
import { Crate } from '../../../models/Crate';
import { Level } from '../../modals/stock-levels-model/stock-levels-modal.component';
import { GroundGroupPosition } from '../data-sources/ground-position-group-data-source.service';

interface GroundPositionResponse {
  position: GroundGroupPosition;
  levels: Level[];
  pallets: Crate[];
}

@Injectable({
  providedIn: 'root'
})
export class GroundPositionService {

  constructor(private http: HttpClient) { }

  getDetails(rfId: string) {
    const endpoint = `${environment.baseUrl}/api/v1/admin-desktop/ground-position/${rfId}`;
    return this.http.get<GroundPositionResponse>(endpoint).toPromise<any>();
  }

  getMapGroundPositions(x?: number, y?: number) {
    const queryParams = { x: x, y: y };
    const params = appendParams(queryParams);
    const endpoint = `${environment.baseUrl}/api/v1/admin-desktop/map/ground-positions`;
    return this.http.get<GroundPositionResponse>(endpoint, { params }).toPromise();
  }

  getAllGroundPositions(type?: GroundPositionType) {
    const queryParams = { type };
    const params = appendParams(queryParams);
    const endpoint = `${environment.baseUrl}/api/v1/admin-desktop/ground-positions`;
    return this.http.get<GroundGroupPosition[]>(endpoint, { params }).toPromise();
  }
}
