import {Component, HostBinding, Input, OnInit, Output, EventEmitter} from '@angular/core';
import * as moment from 'moment';
import { InventoryTypesEnum } from 'src/app/core/services/action.service';
import { getTaskTypeName } from 'src/app/helpers/format-task-type';
import { getGroupOrPositionName } from 'src/app/helpers/get-inventory-type-position-group';
import { TaskType } from 'src/models/History';

export enum TaskStatus {
  QUEUE = 'QUEUE',
  IN_PROCESS = 'IN_PROCESS',
  PENDING = 'PENDING',
  ON_HOLD = 'ON_HOLD',
  ERROR = 'ERROR'
}

@Component({
  selector: 'app-tasks-table-item',
  templateUrl: './tasks-table-item.component.html',
  styleUrls: ['./tasks-table-item.component.scss']
})
export class TasksTableItemComponent implements OnInit {

  @Input() task;
  @Input() @HostBinding('class.isOdd') isOdd: boolean;
  @HostBinding('class.danger-background') isPending = false;
  @Output() deleteClick = new EventEmitter<any>();
  tooltipMessage;

  constructor() { }

  ngOnInit(): void {
    if (this.task.status === TaskStatus.PENDING) {
      this.isPending = true;
    }
    this.tooltipMessage = this.task.type === TaskType.LOAD_DISPATCH ?
      'Esta tarefa não pode ser cancelada diretamente, cancele o despache relacionado.' :
      !this.task.canDelete ? 'Não é possível deletar essa tarefa' : null;
  }

  handleDate(date) {
    if (date) {
      return moment(date).format('DD/MM/YYYY - HH:mm');
    } else {
      return '---';
    }
  }

  translateType(task) {
    switch (task.type) {
      case InventoryTypesEnum.INVENTORY_BY_GROUND_POSITION:
        return getGroupOrPositionName(task, 'groundPositions', 'alias');
      case InventoryTypesEnum.INVENTORY_BY_GROUND_POSITION_GROUP:
        return getGroupOrPositionName(task, 'groundPositionGroups', 'name');
      default:
        return getTaskTypeName(task.type);
    }
  }

  translateStatus(status) {
    switch (status) {
      case TaskStatus.IN_PROCESS:
        return 'Em andamento';
      case TaskStatus.PENDING:
        return 'Pendente';
      case TaskStatus.QUEUE:
        return 'Na fila';
      case TaskStatus.ON_HOLD:
        return 'Em espera';
        case TaskStatus.ERROR:
        return 'Erro';
      default:
        return status || '---';
    }
  }

  handleUsers(users) {
    let sentence = '';
    for (const u of users) {
      if (sentence === '') {
        sentence = u.name;
      } else {
        sentence = sentence + ', ' + u.name;
      }
    }
    return sentence;
  }

  deleteTask() {
    this.deleteClick.emit(this.task.ids);
  }
}
// TODO remover isso após corrigir todos os imports da interface TaskType
export { TaskType };

