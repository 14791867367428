<div class="task-request-section">
  <div class="body">
    <app-sidenav-header title="Limpar posição de Saída" (closeEvent)="close()"></app-sidenav-header>

    <form class="new-inventory-task-config">
      <div class="card-header">Configurar limpeza de posição</div>
      <div class="card-body">
        <p>Selecione a posição que deseja limpar:</p>
        <mat-form-field>
          <mat-label>Posição</mat-label>
          <mat-select [formControl]="selectedPosition" (selectionChange)="onPositionSelected()">
            <mat-option *ngFor="let position of positions" [value]="position">
              {{ position.alias ? 'Posição: ' + position.alias : 'Posição: --- (coordenada: ' + formatCoordinates(position.x, position.y) + ')'}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <p>Pallets selecionados:</p>
        <app-material-generic-table
          [dataSource]="tableData"
          [columnsDefinition]="columnDefinition"
          [displayedColumns]="displayedColumns"
          [emptyDataMessage]="'Posição de saída vazia'">
        </app-material-generic-table>
      </div>
    </form>
  </div>

  <div class="footer">
    <button mat-stroked-button class="gray-2" (click)="close()">CANCELAR</button>
    <button mat-raised-button class="primary-1" (click)="submit()"
      [disabled]="!tableData.length">
      OK
    </button>
  </div>
</div>

<ng-template #notification>
  <app-notification [data]="notificationData" icon="check-circle"></app-notification>
</ng-template>
