import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CratesDataSourceService } from 'src/app/core/data-sources/crates-data-source.service';
import { ErrorService } from '../../core/services/error.service';
import { ExitService } from '../../core/services/exit.service';
import { PaginationService } from '../../core/services/pagination.service';
import { TaskRequestSidenavService } from '../../core/services/task-request-sidenav.service';
import { StockDetailsModalComponent } from '../../modals/stock-details-modal/stock-details-modal.component';
import { Crate } from '../../../models/Crate';
import { StockSortParams } from '../../core/services/stock.service';
import * as moment from 'moment';

@Component({
  selector: 'app-exit',
  templateUrl: './exit.component.html',
  styleUrls: ['./exit.component.scss']
})
export class ExitComponent implements OnInit {
  actualHour = moment().format('HH:mm');
  isModalLoading = false;
  isLoading = false;
  data: Crate[] = [];
  appliedFilters = {};
  sortParams: StockSortParams;
  dataSubscription$: Subscription;
  footerValue = 0;

  constructor(
    public cratesService: CratesDataSourceService,
    public paginationService: PaginationService,
    private router: Router,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private sidenavService: TaskRequestSidenavService,
    private exitService: ExitService
  ) { }

  ngOnDestroy(): void {
    this.dataSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.exitService.init();
    this.getFooterValue();
    this.dataSubscription$ = this.paginationService.items$.subscribe((data) => {
      this.data = data;
      this.isLoading = false;
    });
  }

  async getNextPage(event: boolean) {
    if (event) {
      await this.exitService.getNextPage(this.appliedFilters, this.sortParams);
    }
  }

  async getPreviousPage(event: boolean) {
    if (event) {
      await this.exitService.getPreviousPage(this.appliedFilters, this.sortParams);
    }
  }

  onFiltersApply(params: { sortParams, filterParams }) {
    this.paginationService.currentPage = 1;
    this.appliedFilters = params?.filterParams;
    this.sortParams = params?.sortParams;
    this.update();
  }

  async onRowClick(event) {
    const crate = event.crate;
    try {
      this.isModalLoading = true;
      const itemDetails = await this.cratesService.getCrateById(crate.id);
      this.dialog.open(StockDetailsModalComponent, {
        panelClass: 'details-modal',
        data: {
          item: crate,
          itemDetails,
          isExit: true
        },
        autoFocus: false
      });
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    } finally {
      this.isModalLoading = false;
    }
  }

  async navigateToStock() {
    await this.router.navigate(['/stock']);
    this.dataSubscription$.unsubscribe();
  }

  async openSidenav(type) {
    const data = { type: type, load: true };
    this.sidenavService.toggle(data).then(async (res: any) => {
      if (res) await this.update();
    });
  }

  async update() {
    try {
      this.isLoading = true;
      await this.exitService.reload(this.appliedFilters, this.sortParams);
      this.getFooterValue();
      this.actualHour = moment().format('HH:mm');
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    } finally {
      this.isLoading = false;
    }
  }

  getFooterValue() {
    this.cratesService.getProductsCount("EXIT", this.appliedFilters).then((res) => {
      this.footerValue = res.allProducts.total;
    });
  }

}
