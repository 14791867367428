import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashboard-groups',
  templateUrl: './dashboard-groups.component.html',
  styleUrls: ['./dashboard-groups.component.scss']
})
export class DashboardGroupsComponent implements OnInit {

  @Input() title;
  @Input() disabled = false;
  @Input() color;

  constructor() { }

  ngOnInit(): void {
  }

}
