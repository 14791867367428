import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { appendGroundPositionTypeParams } from 'src/app/helpers/append-query-params';

@Injectable({
  providedIn: 'root'
})
export class StockDataSourceService {

  constructor(private http: HttpClient) { }
  reloadMap$ = new BehaviorSubject(false);

  getStockCrates(stockParams?) {
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/storage', { params: stockParams }).toPromise<any>();
  }

  getVendors() {
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/vendors').toPromise<any>();
  }

  getGroundPosition(readyOnly= false) {
    const params = readyOnly ? '?readyOnly=true' : '';
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/ground/map' + params).toPromise<any>();
  }

  getSearchedProducts(searchTerm) {
    const searchParams = {
      search: searchTerm
    };
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/products/search', { params: searchParams }).toPromise<any>();
  }

  getStockDetails(tagRfid) {
    return this.http.get(environment.baseUrl + `/api/v1/admin-desktop/storage/${tagRfid}`).toPromise<any>();
  }

  getPallets(sku) {
    const queryParams = {
      sku
    };
    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/pallets', { params: queryParams }).toPromise<any>();
  }

  getInventoryPalletOptions(searchTerm) {
    const searchParams = {
      search: searchTerm
    };

    return this.http.get(environment.baseUrl + '/api/v1/admin-desktop/pallets/search', { params: searchParams }).toPromise<any>();
  }

  getStockCratesV2(groundPositionType: string = "STOCK", stockParams?) {
    const queryParams = appendGroundPositionTypeParams(stockParams, groundPositionType);
    return this.http.get(environment.baseUrl + '/api/v2/admin-desktop/storage', { params: queryParams }).toPromise<any>();
  }

  getCSVStorageV2(groundPositionType: string, stockParams?) {
    const queryParams = appendGroundPositionTypeParams(stockParams, groundPositionType);
    return this.http.get(environment.baseUrl + '/api/v2/admin-desktop/csv-storage', { params: queryParams, responseType: 'blob' }).toPromise<any>();
  }
}
