import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionsEnum } from 'src/app/core/data-sources/action-data-source.service';
import { ActionService, CreateProductAction, EditProductAction } from 'src/app/core/services/action.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import * as moment from 'moment';
import { Product } from '../../../models/Product';

interface ModalData {
  product?: Product;
  modalType: "add" | "edit";
  sku?: number;
}

@Component({
  selector: 'app-add-or-edit-product-modal',
  templateUrl: './add-or-edit-product-modal.component.html',
  styleUrls: ['./add-or-edit-product-modal.component.scss']
})
export class AddOrEditProductModalComponent implements OnInit {

  productForm: FormGroup;
  isLoading: boolean;
  imageName = '';
  productImage;
  title = 'Adicionar novo produto';
  btnLabel = 'Criar novo produto';
  modalType: 'add' | 'edit' = 'add';

  constructor(
    public dialogRef: MatDialogRef<AddOrEditProductModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private actionService: ActionService,
    private firebaseService: FirebaseService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.modalType = this.data.modalType;
    if (this.data.sku) {
      this.productForm.controls.sku.setValue(this.data.sku);
      this.productForm.controls.sku.disable();
    }
    if (this.modalType === 'edit') {
      this.title = `Editar produto ${this.data.product.name || this.data.product.sku}`;
      this.btnLabel = 'Confirmar';
      this.setFormValues();
    }
  }

  buildForm() {
    this.productForm = this.formBuilder.group({
      sku: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      weight: [''],
      height: [''],
      width: [''],
      depth: ['']
    });
  }

  setFormValues(): void {
    const formControls = this.productForm.controls;
    formControls.sku.setValue(this.data.product.sku);
    formControls.sku.disable();
    formControls.name.setValue(this.data.product.name);
    formControls.description.setValue(this.data.product.description);
    formControls.weight.setValue(this.data.product.weight);
    formControls.height.setValue(this.data.product.height);
    formControls.width.setValue(this.data.product.width);
    formControls.depth.setValue(this.data.product.depth);
  }

  onImageSelected(event) {
    this.productImage = event.target.files[0];
    this.imageName = this.productImage.name;
  }

  close(): void {
    this.productForm.reset();
    this.dialogRef.close();
  }

  async createProduct(): Promise<void> {
    this.productForm.markAllAsTouched();
    if (this.productForm.valid) {
      try {
        this.isLoading = true;
        const formControls = this.productForm.controls;
        const product = {
          sku: formControls.sku.value,
          name: formControls.name.value,
          description: formControls.description.value,
          weight: formControls.weight.value,
          height: formControls.height.value,
          width: formControls.width.value,
          depth: formControls.depth.value
        };
        this.removeNullKeys(product);
        if (this.modalType === 'add') {
          await this.addProduct(product);
        } else {
          await this.editProduct(product);
        }
        if (formControls.sku.value && this.productImage) {
          await this.uploadPicture();
        }
        this.isLoading = false;
        this.dialogRef.close(true);
      } catch (e) {
        if (e.error.type === "PRODUCT_ALREADY_EXISTS") {
          const message = `O produto de sku ${e.error.data.sku} já existe.`;
          this.errorService.openErrorSnackBarWithCustomMessage(message);
          this.productForm.controls.sku.setErrors({ notUnique: true });
          this.isLoading = false;
          return;
        }
        this.errorService.openErrorSnackBar(e);
        this.isLoading = false;
      }
    }
  }

  async uploadPicture(): Promise<void> {
    const currentTimestamp = moment().valueOf();
    await this.firebaseService.uploadFile(this.productForm.controls.sku.value, this.productImage, currentTimestamp);
    const updatePictureData = {
      sku: this.productForm.controls.sku.value
    };
    await this.actionService.updateProductPicture(updatePictureData);
  }

  async addProduct(product): Promise<void> {
    const createProductData: CreateProductAction = {
      type: ActionsEnum.CREATE_PRODUCT,
      data: product
    };
    await this.actionService.createProduct(createProductData);
  }

  async editProduct(product): Promise<void> {
    const editProductData: EditProductAction = {
      type: ActionsEnum.EDIT_PRODUCT,
      data: product
    };
    await this.actionService.editProduct(editProductData);
  }

  removeNullKeys(params) {
    Object.keys(params).forEach(key => {
      if (params[key] === null || params[key] === '') {
        delete params[key];
      }
    });
  }
}
