<div class="page-header">
  <app-prev-next-button (click)="toDashboard()" direction="left" label="conferência física"></app-prev-next-button>
  <h1>Estoque</h1>
  <app-prev-next-button (click)="toExit()" direction="right" label="saída"></app-prev-next-button>
</div>


<app-pending-task-widget
  (updated)="update()"
  *ngIf="!pendingTaskHasError"
  (errorPendingTask)="visiblePendingTaskError()"
  [pageTitle]="'Estoque'">
</app-pending-task-widget>
<app-pending-task-error-widget *ngIf="pendingTaskHasError"></app-pending-task-error-widget>


<div class="ctrl-bar">
  <div class="buttons">
    <div class="inventory">
      <button mat-raised-button (click)="openSidenav('STOCK_INVENTORY')">
        Inventariar
      </button>
    </div>

    <div class="relocate">
      <button mat-raised-button (click)="openSidenav('STOCK_RELOCATE')">
        Realocar
      </button>
    </div>

    <div class="update-button">
      <p>Atualizado às {{ actualHour }}</p>
      <button mat-raised-button (click)="update()">
        <i class="uil uil-sync"></i>
        <span>Atualizar</span>
      </button>
    </div>
  </div>
</div>

<app-side-color-card color="primary-1">
  <div content class="card-content">
    <div class="card-header">
      <h2>Armazém ({{ (stockService.totalItems$ | async) || '-' }})</h2>

      <div class="page-and-download-buttons">
          <div class="csv-download-button">
            <button mat-raised-button (click)="getCSVStorage()">
              <i class="uil uil-file-download"></i>
              <span>Download CSV</span>
            </button>
          </div>
        <div class="change-page-buttons">
          <button title="anterior" class="previous-page" (click)="getPreviousPage()" [disabled]="stockService.atFirstPage$ | async"><i class="uil uil-arrow-left"></i></button>
<!--          <p>{{ actualPage }}</p>-->
          <p>{{ getPaginationString(stockService.currentPage$ | async, stockService.pageCount$ | async) }}</p>
<!--          <button class="next-page" (click)="getCratesPage(true, filterParams)" [disabled]="stockService.hasNextPage$ | async"><i class="uil uil-arrow-right"></i></button>-->
              <button title="proxima" class="next-page" (click)="getNextPage()"
                  [disabled]="stockService.atLastPage$ | async">
              <i class="uil uil-arrow-right"></i></button>
        </div>

      </div>
    </div>
    <div class="table">
      <app-stock-header *ngIf="!tenantConfig.levelsEnabled"
        (valuesToFilter)="onFiltersApply($event)"
        [valuesApplied]="filterParams">
      </app-stock-header>
      <app-stock-header-with-level *ngIf="tenantConfig.levelsEnabled"
        (valuesToFilter)="onFiltersApply($event)"
        [valuesApplied]="filterParams"
      >
      </app-stock-header-with-level>

      <div *ngIf="isLoading" class="loader">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>

      <div class="empty" *ngIf="items?.length === 0 && !isLoading">
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>

      <ng-container *ngIf="!tenantConfig.levelsEnabled && !isLoading">
        <app-stock-item
          *ngFor="let crate of items; let odd = odd"
          [crate]="crate"
          (clicked)="itemColumnClicked($event)"
          [ngClass]="{'isOdd': odd}">
        </app-stock-item>
      </ng-container>
      <ng-container *ngIf="tenantConfig.levelsEnabled && !isLoading">
        <app-stock-item-with-level
          *ngFor="let crate of items; let odd = odd"
          [crate]="crate"
          [checked]="selectedCratesIds.has(crate.id)"
          (clicked)="itemColumnClicked($event)"
          [ngClass]="{'isOdd': odd}">
        </app-stock-item-with-level>
      </ng-container>
      <app-stock-footer [value]="footerValue"></app-stock-footer>
    </div>
  </div>
</app-side-color-card>

<app-full-page-loader *ngIf="isModalLoading"></app-full-page-loader>
