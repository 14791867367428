<app-exit-table-header (filterValues)="onFiltersApply($event)"></app-exit-table-header>
<app-table-items>
  <app-table-item *ngFor="let crate of crates; let odd = odd" [isOdd]="odd">

    <app-table-column name="createdAt" size="small" (click)="itemClicked(crate)">
      <span content>{{formatDate(crate.createdAt)}}</span>
    </app-table-column>

    <app-table-column name="lastMovedAt" size="small" (click)="itemClicked(crate)">
      <span content>{{formatDate(crate.lastMovedAt)}}</span>
    </app-table-column>

    <app-table-column name="section" size="small" (click)="itemClicked(crate)">
      <span content>{{crate.section || '---'}}</span>
    </app-table-column>

    <app-table-column name="position" size="smaller" (click)="itemClicked(crate)">
      <span content>  {{ toExcelFormat(crate.x, crate.y) }}  </span>
    </app-table-column>

    <app-table-column name="alias" size="smallest" (click)="itemClicked(crate)">
      <span content>{{crate.alias || '---'}}</span>
    </app-table-column>
<!-- 
    <app-table-column name="levelName" size="smaller" (click)="itemClicked(crate)">
      <span content>{{crate.levelName || '---'}}</span>
    </app-table-column>

    <app-table-column name="height" size="smallest" (click)="itemClicked(crate)">
      <span content>{{ crate.z >= 0 ? centimetersToMeters(crate.z) + ' metros' : 'Conflitante' }}</span>
    </app-table-column>
   -->

    <app-table-column name="type" size="smaller" (click)="itemClicked(crate)">
      <span content>{{getCrateType(crate.type)}}</span>
    </app-table-column>

    <app-table-column name="product-code" size="small" (click)="itemClicked(crate)">
      <span content>
        <span *ngFor="let product of crate.skuGroups">{{ product.sku }}</span>
      </span>
    </app-table-column>

    <app-table-column name="description" size="medium" (click)="itemClicked(crate)">
      <span content>
        <span *ngFor="let product of crate.skuGroups">{{ product.name }}</span>
      </span>
    </app-table-column>

    <app-table-column name="quantity" size="qtd" alignRight="true" (click)="itemClicked(crate)">
      <span content>
        <span *ngFor="let product of crate.skuGroups">
          {{ product.quantity * product.packSize | number : '1.0-0' : 'pt-BR' }}
        </span>
      </span>
    </app-table-column>

  </app-table-item>
</app-table-items>