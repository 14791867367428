import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-info-oc-or-nfe',
  templateUrl: './info-oc-or-nfe.component.html',
  styleUrls: ['./info-oc-or-nfe.component.scss']
})
export class InfoOcOrNfeComponent implements OnInit {

  @Input() label;
  @Input() value;
  @Input() serial;
  @Input() valueColor;
  @Input() isStatus = false; // status-icon

  constructor() { }

  ngOnInit(): void {
  }

}
