import { EventType } from "../../models/History";
import { TaskType } from "../pages/tasks/tasks-table-item/tasks-table-item.component";

const taskTypeName = new Map<TaskType, string>([
  [TaskType.RECEIVING, "Recebimento"],
  [TaskType.DISPATCHING, "Expedição"],
  [TaskType.LOAD_DISPATCH, "Saída"],
  [TaskType.RELOCATE_STORAGE, "Movimentação"],
  [TaskType.RELOCATE_POSITION, "Movimentação"],
  [TaskType.RELOCATE_EXPEDITION, "Movimentação"],
  [TaskType.RELOCATE_DIVERGENT, "Movimentação"],
  [TaskType.RELOCATE_QUALITY, "Movimentação"],
  [TaskType.RELOCATE_DOCK, "Movimentação"],
  [TaskType.RELOCATE_FREE, "Movimentação Livre"],
  [TaskType.RELOCATE_FROM_PICKING, "Realocação para separação"],
  [TaskType.INVENTORY_BY_GROUND_POSITION_GROUP, "Inventário por setor"],
  [TaskType.INVENTORY_BY_PRODUCT, "Inventário por produto"],
  [TaskType.INVENTORY_BY_TAG_RFID, "Inventário por tag RFID"],
  [TaskType.INVENTORY_BY_GROUND_POSITION, "Inventário por posição"],
  [TaskType.PRODUCT_INVENTORY_BY_CRATE_TAG_RFID, "Inventário por pallet"],
  [TaskType.INVENTORY_BY_MISSING_CRATE, "Inventário por pallet perdido"],
  [TaskType.PRODUCT_PICKING, "Separação de produto"],
  [TaskType.PICKING, "Separação"],
  [TaskType.EDIT_PALLET, "Edição de Pallet"],
  [TaskType.EXIT_BY_GROUND_POSITION, "Saída por posição"],
  [TaskType.EXIT_MISSING_PALLETS, "Saída por inventário"],
  [TaskType.RECEIVING_INVOICE, "Recebimento com nota"],
  [TaskType.RECEIVING_NO_INVOICE, "Recebimento sem nota"],
  [TaskType.CREATE_CRATE, "Recebimento direto no estoque"],
  [TaskType.TAKE_SOME_PRODUCTS_TO_EXIT, "Expedição fracionada"],
]);

const EventTypeName = new Map<EventType, string>([
  [EventType.FOUND, "Encontrado por inventário"],
  [EventType.FOUND_MISPLACED, "Encontrado em lugar indevido"],
  [EventType.MISSING, "Perdido por inventário"],
  [EventType.LOAD, "Início de movimentação"],
  [EventType.UNLOAD, "Fim de movimentação"],
  [EventType.EXIT, "Saída"],
  [EventType.ENTRY, "Entrada"],
  [EventType.EDIT_PRODUCT, "Edição de Produtos"]
]);

export function getTaskTypeName(taskType: TaskType): string {
  return taskTypeName.get(taskType) || "---";
}

export function getEventTypeName(EventType: EventType): string {
  return EventTypeName.get(EventType) || "---";
}
