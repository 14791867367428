<div class="task-request-section">
  <div class="body">
    <app-sidenav-header title="Solicitação de tarefa" (closeEvent)="close()"></app-sidenav-header>

    <form class="new-inventory-task-config">
      <div class="card-header">Configurar nova tarefa de inventário</div>
      <div class="card-body">
        <p>Inventariar por:</p>
        <div class="buttons">
          <button
            mat-raised-button
            class="secondary-2">
            Pallets com Endereço Desconhecido
          </button>
        </div>

        <p>Pallets selecionados:</p>
        <app-material-generic-table
          [dataSource]="inventoryTableData"
          [columnsDefinition]="columnDefinition"
          [displayedColumns]="displayedColumns"
          [hasActions]="true"
          (elementToDelete)="removeSelectedPallet($event)">
        </app-material-generic-table>
      </div>
    </form>

    <p class="error" *ngIf="selectOperatorError">Selecione um ou mais operadores para atribuir a tarefa.</p>

    <app-operators-table device="HANDSCANNER"
      [usersHighlighted]="usersHighlighted"
      (usersHighlightedEvent)="setUsers($event)"
      [openedStart]="drawer.openedStart"
      [isForklift]="false"
      [isUserOnline]="true">
    </app-operators-table>
  </div>

  <div class="footer">
    <button mat-stroked-button class="gray-2" (click)="close()">CANCELAR</button>
    <button mat-raised-button class="primary-1" (click)="submit()"
      [disabled]="!inventoryTableData.length || !usersHighlighted.length">
      OK
    </button>
  </div>
</div>
