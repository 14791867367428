<section class="container" *ngFor="let pallet of pallets" id="{{pallet.tagRfid}}">
  <div class="details">
    <div class="row warning" *ngIf="pallet.position === 'SECTION'">
      <i class="uil uil-exclamation-triangle"></i>
      <p>
        Esta é a posição no momento da leitura do recebimento deste pallet. Alterações da posição podem ter sido
        realizadas no processo de descarregamento.
      </p>
    </div>

    <div class="col">
      <app-info-oc-or-nfe label="rfId" [value]="pallet.tagRfid"
        *ngIf="pallet.type !== CrateTypeEnum.REMAINS">
      </app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="rfId" [value]="'N/A'"
        *ngIf="pallet.type === CrateTypeEnum.REMAINS">
      </app-info-oc-or-nfe>
    </div>
    <div class="row bottom">
      <section>
        <app-info-oc-or-nfe label="Nível" *ngIf="pallet?.groundPositionGroupLevelName?.length"
          [value]="pallet.groundPositionGroupLevelName">
        </app-info-oc-or-nfe>
        <app-info-oc-or-nfe label="Altura" *ngIf="!pallet?.groundPositionGroupLevelName?.length"
          [value]="pallet.z >= 0 ? pallet.z + 'cm' : 'indefinida'">
        </app-info-oc-or-nfe>
        <app-info-oc-or-nfe label="Tipo" [value]="formatType(pallet.type)"></app-info-oc-or-nfe>
        <app-info-oc-or-nfe label="Estado" [value]="translatePalletState(pallet.state)"></app-info-oc-or-nfe>
      </section>
      <section class="btn-container">
        <button mat-raised-button (click)="openPalletDetails(pallet)">
          Dados Do Pallet
        </button>
      </section>
    </div>
  </div>

  <div class="products">
    <div class="table">
      <div class="header">
        <div class="sku column">
          Sku
        </div>
        <div class="name column">
          Nome
        </div>
        <div class="quantity column">
          Quantidade
        </div>
      </div>

      <div class="item" *ngFor="let product of pallet.skuGroups">
        <div class="sku column">
          {{ product.sku }}
        </div>
        <div class="name column">
          {{ product.name }}
        </div>
        <div class="quantity column">
          {{ product.packSize * product.quantity }}
        </div>
      </div>
    </div>
  </div>
</section>