import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { TenantService } from '../services/tenant.service';
import { TenantInterface } from '../../../models/Tenant';

@Injectable({
  providedIn: 'root'
})
export class TenantProcessGuard implements CanActivate {
  tenant: TenantInterface;
  constructor(
    private tenantService: TenantService, 
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const res = await this.tenantService.get();
    this.tenant = res;
    if (this.tenant?.process?.[route.data.process]) {
      return true;
    } else {
      this.router.navigateByUrl('/dashboard');
      return false;
    }
  }

}
