import { CrateType } from './../../models/Crate';

const taskTypeName = new Map<CrateType, string>([
  [CrateType.PALLET, "Pallet"],
  [CrateType.REMAINS, "Fracionado"],
]);

export function getCrateTypeName(crateType: CrateType): string {
  return taskTypeName.get(crateType) || "---";
}
