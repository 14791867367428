import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-material-generic-table',
  templateUrl: './angular-material-generic-table.component.html',
  styleUrls: ['./angular-material-generic-table.component.scss']
})
export class AngularMaterialGenericTableComponent implements OnInit {
  @Input() displayedColumns: string[] = [];
  @Input() columnsDefinition: string[] = [];
  @Input() dataSource: any[] = [];
  @Input() hasActions: boolean = false;
  @Input() emptyDataMessage = 'Não existem produtos neste pallet';
  @Output() elementToDelete = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  remove(element) {
    this.elementToDelete.emit(element);
  }

}
