<mat-tab-group mat-align-tabs="center"
  [selectedIndex]="selected.value"
  (selectedIndexChange)="onSelectedIndexChange($event)">
  <mat-tab label="Pallet">
    <app-pallet-details
      [isExit]="data.isExit"
      [crateDetails]="data.itemDetails"
      [isMap]="true"
      (relocateClicked)="onRelocateClicked($event)">
    </app-pallet-details>
  </mat-tab>
  <mat-tab label="Histórico">
    <app-movement-details *ngIf="loadEventTab"
      [crateId]="data.itemDetails.crate.id"
      [warning]="remainsPalletWarning">
    </app-movement-details>
  </mat-tab>
  <mat-tab label="Detalhes dos produtos">
    <app-product-image-tab [products]="products"></app-product-image-tab>
  </mat-tab>
  <mat-tab label="Comentários">
    <app-in-details-comments [itemDetails]="data.itemDetails"></app-in-details-comments>
  </mat-tab>
</mat-tab-group>