<div class="page-header">
  <app-prev-next-button (click)="toSetup()" direction="left" label="setup"></app-prev-next-button>
  <h1>Revisão do Setup</h1>
</div>

<div class="page-content">
  <app-side-color-card color="out">
    <div class="content" content>
      <div class="line">
        <div class="label">nome do armazém</div>
        <div class="contrl"> {{name}}</div>
      </div>
      <div class="line">
        <div class="label">id do armazém (tenantId)</div>
        <div class="contrl"> {{id}}</div>
      </div>
    </div>
  </app-side-color-card>
  <app-side-color-card color="out">
    <div class="content" content>
      <div class="line">
        <div class="label">erp</div>
        <div class="contrl">
          <mat-select #select [value]="selectedErp" (valueChange)="changeErp($event)">
            <mat-option *ngFor="let e of erps" [value]="e">{{e}}</mat-option>
          </mat-select>
        </div>
      </div>
        <form [formGroup]="dummyDetails" *ngIf="selectedErp === 'DUMMY'">
        <div class="line">
          <div class="label">url</div>
          <div class="contrl">
            <input matInput
                   class="gray"
                   placeholder="url da integração"
                   formControlName="url">
          </div>
        </div>
        </form>
      <form [formGroup]="protheusDetails" *ngIf="selectedErp === 'PROTHEUS'">
        <div class="line">
          <div class="label">url</div>
          <div class="contrl">
            <input matInput
                   class="gray"
                   placeholder="url da integração"
                   formControlName="url">
          </div>
        </div>
      </form>
      <div class="line">
        <div class="label">
          gerenciar produtos através do quantum
          <span class="uil uil-question-circle" [matTooltip]="internalProductsTooltip"></span>
        </div>
        <div class="contrl end">
          <mat-checkbox
            [(ngModel)]="useInternalProducts"
            color="primary">
          </mat-checkbox>
        </div>
      </div>
      <div class="line">
        <div class="label">
          habilitar edição de crates pelo coletor
        </div>
        <div class="contrl end">
          <mat-checkbox
            [(ngModel)]="enableEditionProductByHandScanner"
            color="primary">
          </mat-checkbox>
        </div>
      </div>
    </div>
  </app-side-color-card>
  <app-side-color-card color="out">
    <div class="content" content>
      <div class="line">
        <h4 class="title">Habilitar Processos</h4>
      </div>
      <form [formGroup]="permissionsForm">
        <div class="line">
          <div class="label">Recebimento</div>
          <div class="contrl end">
            <mat-checkbox formControlName="in" color="primary"></mat-checkbox>
          </div>
        </div>
        <div class="line">
          <div class="label">Armazém</div>
          <div class="contrl end">
            <mat-checkbox formControlName="stock" color="primary"></mat-checkbox>
          </div>
        </div>
        <div class="line">
          <div class="label">Separação</div>
          <div class="contrl end">
            <mat-checkbox formControlName="packing" color="primary"></mat-checkbox>
          </div>
        </div>
        <div class="line">
          <div class="label">Expedição</div>
          <div class="contrl end">
            <mat-checkbox formControlName="out" color="primary"></mat-checkbox>
          </div>
        </div>
      </form>
    </div>
  </app-side-color-card>

  <div class="buttons">
    <button mat-raised-button (click)="finish()">
      Finalizar Setup
    </button>
  </div>



</div>
