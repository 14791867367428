import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PaginationService } from './pagination.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {

  constructor(
    private http: HttpClient,
    private paginationService: PaginationService
  ) { }

  getGoodsV3(params?) {
    return this.http.get(environment.v3BaseUrl + '/api/v3/admin-desktop/physical-inspection', { params }).toPromise<any>();
  }

  getGoodDetails(tagRfid) {
    return this.http.get(environment.baseUrl + `/api/v1/admin-desktop/physical-inspection/${tagRfid}`).toPromise();
  }

  async init() {
    return this.getPage(1);
  }

  async reload() {
    return await this.getPage(this.paginationService.currentPage);
  }

  async getNextPage() {
    if (this.paginationService.currentPage < this.paginationService.pageCount) {
      return await this.getPage(this.paginationService.currentPage + 1);
    } else {
      throw Error('???');
    }
  }

  async getPreviousPage() {
    if (this.paginationService.currentPage > 1) {
      return await this.getPage(this.paginationService.currentPage - 1);
    } else {
      throw Error('???');
    }
  }

  private async getPage(page: number) {
    this.paginationService.currentPage = page;
    const params = {
      limit: this.paginationService.pageSize,
      offset: (this.paginationService.currentPage - 1) * this.paginationService.pageSize
    };
    const response = await this.getGoodsV3(params);
    this.paginationService.totalItems = response.count;
    this.paginationService.pageCount = Math.ceil(this.paginationService.totalItems / this.paginationService.pageSize);
    this.paginationService.$items.next(response.values);
    this.paginationService.$pageCount.next(this.paginationService.pageCount);
    this.paginationService.$totalItems.next(this.paginationService.totalItems);
    this.paginationService.$currentPage.next(this.paginationService.currentPage);
    this.paginationService.$atLastPage.next(this.paginationService.pageCount === 0 || this.paginationService.currentPage === this.paginationService.pageCount);
    this.paginationService.$atFirstPage.next(this.paginationService.currentPage === 1);
  }
}
