<ng-container [formGroup]="form">
  <app-table-header-column name="createdAt" size="small">
    <span content>
      Entrada
      <app-filter-button
        [column]="'createdAt'"
        [menu]="createdAtFilterMenu"
        [appliedFilters]="appliedFilters"
        [sortColumn]="sortColumn"
        #createdAtFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #createdAtFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por data de entrada</h4>
      <button (click)="sort('createdAt', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('createdAt', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <mat-label>Data inicial</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" required [max]="maxDate" disabled>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Data final</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="endDate"
          required [max]="maxDate" disabled (dateChange)="onEndDateChange()"
        >
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters(createdAtFilterBtn.menuTrigger, 'createdAt')">
            Limpar
          </button>
          <button mat-raised-button class="primary-1" (click)="applyFilters(createdAtFilterBtn.menuTrigger)"
            [disabled]="!showCheckIcon['createdAt'] && (form.get('startDate').invalid || form.get('endDate').invalid)">
            Salvar
          </button>
        </section>
    </div>
  </mat-menu>

  <app-table-header-column name="lastMovedAt" size="small">
    <span content>
      Últ movimentação
      <app-filter-button
        [column]="'lastMovedAt'"
        [menu]="lastMovedAtFilterMenu"
        [appliedFilters]="appliedFilters"
        [sortColumn]="sortColumn"
        #lastMovedAtFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #lastMovedAtFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Ordenar por última movimentação</h4>
      <button (click)="sort('lastMovedAt', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['lastMovedAt'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('lastMovedAt', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['lastMovedAt'] && sortDirection == 'ASC'"></i>
      </button>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters(lastMovedAtFilterBtn.menuTrigger, 'lastMovedAt')">
          Limpar
        </button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(lastMovedAtFilterBtn.menuTrigger)">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="section" size="small">
    <span content>
      Setor
      <app-filter-button
        [column]="'section'"
        [menu]="sectionFilterMenu"
        [appliedFilters]="appliedFilters"
        [sortColumn]="sortColumn"
        #sectionFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #sectionFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por setor</h4>
      <button (click)="sort('section', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['section'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('section', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['section'] && sortDirection == 'ASC'"></i>
      </button>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Setor" formControlName="section" autocomplete="off">
        </mat-form-field>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters(sectionFilterBtn.menuTrigger, 'section')">
            Limpar
          </button>
          <button mat-raised-button class="primary-1" (click)="applyFilters(sectionFilterBtn.menuTrigger)">Salvar</button>
        </section>
    </div>
  </mat-menu>

  <app-table-header-column name="position" size="smaller">
    <span content>
      Coordenada
      <app-filter-button
        [column]="'position'"
        [menu]="positionFilterMenu"
        [appliedFilters]="appliedFilters"
        [sortColumn]="sortColumn"
        #positionFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #positionFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por coordenada</h4>
      <button (click)="sort('position', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['position'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('position', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['position'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Coordenada" formControlName="position" autocomplete="off">
        <mat-error>Coordenada inválida</mat-error>
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters(positionFilterBtn.menuTrigger, 'position')">
          Limpar
        </button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(positionFilterBtn.menuTrigger)"
          [disabled]="form.get('position').value?.length <= 1">
          Salvar
        </button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="alias" size="smallest">
    <span content>
      Posição
      <app-filter-button
        [column]="'alias'"
        [menu]="aliasFilterMenu"
        [appliedFilters]="appliedFilters"
        [sortColumn]="sortColumn"
        #aliasFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #aliasFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por posição</h4>
      <button (click)="sort('alias', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['alias'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('alias', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['alias'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Posição" formControlName="alias" autocomplete="off">
        <mat-error>Posição inválida</mat-error>
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters(aliasFilterBtn.menuTrigger, 'alias')">
          Limpar
        </button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(aliasFilterBtn.menuTrigger)">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <!-- <app-table-header-column name="levelName" size="smaller">
    <span content>
      Nível
      <app-filter-button
        [column]="'levelName'"
        [menu]="levelNameFilterMenu"
        [appliedFilters]="appliedFilters"
        [sortColumn]="sortColumn"
        #levelFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #levelNameFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por nível</h4>
      <button (click)="sort('levelName', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['levelName'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('levelName', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['levelName'] && sortDirection == 'ASC'"></i>
      </button>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Nível" formControlName="levelName" autocomplete="off">
        </mat-form-field>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters(levelFilterBtn.menuTrigger, 'levelName')">
            Limpar
          </button>
          <button mat-raised-button class="primary-1" (click)="applyFilters(levelFilterBtn.menuTrigger)">Salvar</button>
        </section>
    </div>
  </mat-menu>

  <app-table-header-column name="height" size="smallest">
    <span content>
      Altura
      <app-filter-button
        [column]="'z'"
        [menu]="heightFilterMenu"
        [appliedFilters]="appliedFilters"
        [sortColumn]="sortColumn"
        #heightFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #heightFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Ordenar por altura</h4>
      <button (click)="sort('z', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['z'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('z', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['z'] && sortDirection == 'ASC'"></i>
      </button>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters(heightFilterBtn.menuTrigger, 'z')">
          Limpar
        </button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(heightFilterBtn.menuTrigger)">Salvar</button>
      </section>
    </div>
  </mat-menu> -->

  <app-table-header-column name="type" size="smaller">
    <span content>Tipo</span>
  </app-table-header-column>

  <app-table-header-column name="product-code" size="small">
    <span content>
      Código de produto
      <app-filter-button
        [column]="'sku'"
        [menu]="productSkuFilterMenu"
        [appliedFilters]="appliedFilters"
        [sortColumn]="sortColumn"
        #skuFilterBtn>
      </app-filter-button>
    </span>

  </app-table-header-column>

  <mat-menu #productSkuFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por código de produto</h4>
      <button (click)="sort('sku', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['sku'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('sku', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['sku'] && sortDirection == 'ASC'"></i>
      </button>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Código do produto" formControlName="sku" autocomplete="off">
        </mat-form-field>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters(skuFilterBtn.menuTrigger, 'sku')">
            Limpar
          </button>
          <button mat-raised-button class="primary-1" (click)="applyFilters(skuFilterBtn.menuTrigger)">Salvar</button>
        </section>
    </div>
  </mat-menu>

  <app-table-header-column name="description" size="medium">
    <span content>Descrição</span>
  </app-table-header-column>

  <app-table-header-column name="quantity" size="qtd" alignRight="true">
    <span content>
      Qtd
      <app-filter-button
        [column]="'quantity'"
        [menu]="quantityFilterMenu"
        [appliedFilters]="appliedFilters"
        [sortColumn]="sortColumn"
        #quantityFilterBtn>
      </app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #quantityFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por quantidade</h4>
      <button (click)="sort('quantity', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['quantity'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('quantity', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['quantity'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Quantidade" type="number" formControlName="quantity" min="1" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1"
          (click)="clearFilters(quantityFilterBtn.menuTrigger, 'quantity')">
          Limpar
        </button>
        <button mat-raised-button class="primary-1" (click)="applyFilters(quantityFilterBtn.menuTrigger)">Salvar</button>
      </section>
    </div>
  </mat-menu>

</ng-container>
