import {Component, ElementRef, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import * as PIXI from 'pixi.js';
import Application = PIXI.Application;
import {LayoutService} from '../../core/data-sources/layout.service';
import { Viewport } from 'pixi-viewport';
import {Scrollbox} from 'pixi-scrollbox';
import {drawColumns, drawRows} from '../../pages/setup/setup-ground/ground-groups-map/ground-cell';

export interface GroundPosition {
  rfId: string;
  x: number;
  y: number;
  line: string;
  column: string;
  type: string;
  groupName: string;
  groupId: string;
  hasPallet: boolean;
  state: string;
}

@Component({
  selector: 'app-map',
  template: ''
})
export class MapViewComponent implements OnInit, OnDestroy {

  public app: Application;
  public viewPort: Viewport;
  public scrollBox: Scrollbox;

  public options;

  @Input()
  public devicePixelRatio = window.devicePixelRatio || 1;

  constructor(
    protected elementRef: ElementRef,
    protected ngZone: NgZone
  ) {}

  initMap(maxWidth?, maxHeight?) {
    const border = 20;
    const canvasHeight = this.elementRef.nativeElement.offsetHeight;
    const canvasWidth =  this.elementRef.nativeElement.offsetWidth;
    this.ngZone.runOutsideAngular(() => {
      const options = {
        backgroundColor: 0xffffff,
        width: canvasWidth,
        height: canvasHeight,
        resizeTo:  this.elementRef.nativeElement,
      };
      this.app = new Application(options);

      // @ts-ignore
      this.scrollBox = new Scrollbox({
        boxWidth: options.width,
        boxHeight: options.height,
        divWheel: this.elementRef.nativeElement
      });

      this.app.stage.addChild(this.scrollBox);
      this.viewPort = this.scrollBox.content;
      const minScale = this.viewPort.findFitWidth(maxWidth + 2 * border);
      this.viewPort.worldHeight = maxHeight + 2 * border;
      this.viewPort.worldWidth = maxWidth + 2 * border;
      this.viewPort
        .wheel()
        .clamp({underflow: 'top-left', left: true, top: true})
        .clampZoom({ minScale, maxScale: 20 });
        // .fit(false, this.viewPort.worldWidth, this.viewPort.worldHeight);
      this.scrollBox.update();
    });
    this.elementRef.nativeElement.appendChild(this.app.view);
    // this.resize();
  }

  public goTo(x,y){
    this.viewPort.moveCenter(x, y);
  }

  ngOnInit(): void {
    this.initMap();
  }

  @HostListener('window:resize')
  public resize() {
    //const {width, height} = this.app.screen;
    //this.scrollBox.resize({boxHeight:height, boxWidth:width});
  }

  public clearContent(){
    // @ts-ignore
    while(this.scrollBox.content.children.length > 0){
      // @ts-ignore
      const child = this.scrollBox.content.getChildAt(0);
      // @ts-ignore
      child.destroy();
      // @ts-ignore
      this.scrollBox.content.removeChild(child);
    }
  }

  destroy() {
    this.app?.destroy(); // without the null operator, it throws an undefined error if user token is expired and app destroy is not executed.
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  drawGrades(groundPositions: GroundPosition[]) {
    const gradePositions = this.getMaxCoordinates(groundPositions);
    const columns = drawColumns(gradePositions.positionsX);
    for (const c of columns) {
      // @ts-ignore
      this.viewPort.addChild(c);
    }
    const rows = drawRows(gradePositions.positionsY);
    for (const r of rows) {
      // @ts-ignore
      this.viewPort.addChild(r);
    }
  }
  getMaxCoordinates(groundPositions) {
    const positionsX = [];
    const positionsY = [];
    let x = 0;
    let y = 0;
    for (const gp of groundPositions) {
      if (gp.x > x) { x = gp.x; }
      if (gp.y > y) { y = gp.y; }
    }
    let countX = 0;
    while (countX <= x) {
      positionsX.push(countX);
      countX++;
    }
    let countY = 0;
    while (countY <= y) {
      positionsY.push(countY);
      countY++;
    }

    return {positionsX, positionsY};
  }


}
