import { StockDataSourceService } from '../../core/data-sources/stock-data-source.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaginationService } from '../../core/services/pagination.service';
import { Subscription } from 'rxjs';
import { MissingCrateSortParams, MissingCratesService } from '../../core/services/missing-crates.service';
import { Router } from '@angular/router';
import { ErrorService } from '../../core/services/error.service';
import { MatDialog } from '@angular/material/dialog';
import { StockDetailsModalComponent } from '../../modals/stock-details-modal/stock-details-modal.component';
import { TaskRequestSidenavService } from 'src/app/core/services/task-request-sidenav.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DEFAULT_DATE_FORMAT } from 'src/app/helpers/date-format.helper';
import * as moment from 'moment';
import { MissingCrate } from 'src/models/Crate';

@Component({
  selector: 'app-unknown-address',
  templateUrl: './unknown-address.component.html',
  styleUrls: ['./unknown-address.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DEFAULT_DATE_FORMAT }]
})
export class UnknownAddressComponent implements OnInit, OnDestroy {

  actualHour = moment().format('HH:mm');
  isModalLoading = false;
  isLoading = false;
  data: MissingCrate[] = [];
  appliedFilters = {};
  sortParams: MissingCrateSortParams;
  dataSubscription$: Subscription;
  selectedPalletsSubscription$: Subscription;
  selectedPallets: MissingCrate[];

  constructor(
    public missingPalletsService: MissingCratesService,
    public paginationService: PaginationService,
    private router: Router,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private stockDataSourceService: StockDataSourceService,
    private sidenavService: TaskRequestSidenavService
  ) { }

  ngOnDestroy(): void {
    this.selectedPalletsSubscription$.unsubscribe();
    this.dataSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.missingPalletsService.init();
    this.selectedPalletsSubscription$ = this.missingPalletsService.getMissingPallets().subscribe(value => {
      this.selectedPallets = value;
    });
    this.dataSubscription$ = this.paginationService.items$.subscribe((data) => {
      this.data = data;
      this.isLoading = false;
    });
  }

  async getNextPage(event: boolean) {
    if (event) {
      await this.missingPalletsService.getNextPage(this.appliedFilters, this.sortParams);
    }
  }

  async getPreviousPage(event: boolean) {
    if (event) {
      await this.missingPalletsService.getPreviousPage(this.appliedFilters, this.sortParams);
    }
  }

  onFiltersApply(params: { sortParams, filterParams }) {
    this.appliedFilters = params?.filterParams;
    this.sortParams = params?.sortParams;
    this.update();
  }

  async onRowClick(event) {
    const crate = event.crate;
    try {
      this.isModalLoading = true;
      // TODO: change to crates/:tagRfid when this api is ready
      const itemDetails = await this.stockDataSourceService.getStockDetails(crate.tagRfid).then((r: any) => {
        r.invoice = r.crate.invoices[0];
        return r;
      });
      this.dialog.open(StockDetailsModalComponent, {
        panelClass: 'details-modal',
        data: {
          item: crate,
          isMissingPallet: true,
          itemDetails
        },
        autoFocus: false
      });
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    } finally {
      this.isModalLoading = false;
    }
  }

  async navigateToStock() {
    await this.router.navigate(['stock']);
    this.dataSubscription$.unsubscribe();
    this.selectedPalletsSubscription$.unsubscribe();
  }

  async update() {
    try {
      this.isLoading = true;
      this.data.forEach(item => item.selected = false);
      this.missingPalletsService.missingPallets$.next([]);
      await this.missingPalletsService.reload(this.appliedFilters, this.sortParams);
      this.actualHour = moment().format('HH:mm');
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    } finally {
      this.isLoading = false;
    }
  }

  async openSidenav(type) {
    const data = { type };
    this.sidenavService.toggle(data).then(async (res: any) => {
      if (!res.keepSelection) await this.update();
    });
  }

}
