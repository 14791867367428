import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActionsEnum } from '../../../core/data-sources/action-data-source.service';
import { ActionService } from '../../../core/services/action.service';
import { GroundPositionService } from '../../../core/services/ground-position.service';
import { TaskRequestSidenavService } from '../../../core/services/task-request-sidenav.service';
import { GroundPositionType } from '../../warehouse-map/warehouse-map-table/warehouse-map-table.component';
import { toExcelFormat } from '../../../helpers/format-position';
import { ErrorService } from '../../../core/services/error.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { GroundGroupPosition } from '../../../core/data-sources/ground-position-group-data-source.service';
import { getCrateTypeName } from '../../../helpers/format-crate-type';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../../modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-exit-sidenav',
  templateUrl: './exit-sidenav.component.html',
  styleUrls: ['./exit-sidenav.component.scss']
})
export class ExitSidenavComponent implements OnInit, OnChanges {
  @Input() drawer;
  @Input() data;
  @Input() clearData = false;
  selectedPosition = new FormControl(null);
  displayedColumns = ['tagRfid', 'type', 'sku', 'quantity', 'alias'];
  columnDefinition = [
    { key: "tagRfid", text: "Tag Rfid", showTooltip: true },
    { key: "type", text: "Tipo" },
    { key: "sku", text: "Código do produto" },
    { key: "quantity", text: "Quantidade" },
    { key: "alias", text: "Posição" }
  ];
  tableData = [];
  notificationData: { type: string; title: string; message: string; };
  @ViewChild('notification', { static: true }) notificationTmpl;
  positions: GroundGroupPosition[] = [];
  position: GroundGroupPosition;

  constructor(
    private actionService: ActionService,
    private sidenavService: TaskRequestSidenavService,
    private groundPositionService: GroundPositionService,
    private errorService: ErrorService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
  ) { }

  ngOnChanges(): void {
    if (this.clearData) {
      this.clearSidenavData();
    }
    if (!this.positions.length && this.data?.load) {
      this.getExitPositions();
    }
  }

  ngOnInit(): void {}

  formatCoordinates(x, y) {
    return toExcelFormat(x, y);
  }

  getExitPositions() {
    this.groundPositionService.getAllGroundPositions(GroundPositionType.EXIT).then(res => {
      this.positions = res;
    });
  }

  getTableData() {
    this.groundPositionService.getDetails(this.selectedPosition.value.rfId).then((res: any) => {
      this.position = res.position;
      this.position.coords = `${toExcelFormat(res.position.x, res.position.y)} (x:${res.position.x}, y:${res.position.y})`;
      this.formatTableData(res.pallets);
    });
  }

  onPositionSelected() {
    this.getTableData();
  }

  formatTableData(data) {
    this.tableData = data.reduce((acc, element) => {
      const formatted = element.skuGroups.map(item => ({
        quantity: item.quantity * item.packSize,
        sku: item.sku,
        tagRfid: element.tagRfid ?? '---',
        alias: element.alias ?? '---',
        id: element.id,
        type: getCrateTypeName(element.type)
      }));
      return acc.concat(formatted);
    }, []);
  }

  close() {
    this.clearSidenavData();
    this.drawer.close();
    this.sidenavService.closeSidenav(false);
  }

  async submit() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'confirm-modal',
      autoFocus: false,
      data: {
        label: `Você tem certeza que deseja remover o(s) ${this.tableData.length} pallet(s) da posição ${this.position.coords}?`,
        confirmBtnClass: 'alert'
      }
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const rfids = [];
        rfids.push(this.position.rfId);
        try {
          await this.actionService.removePalletsFromExitPosition({
            type: ActionsEnum.EXIT_BY_GROUND_POSITION,
            data: { rfids: rfids }
          });
          this.notificationData = {
            type: 'success',
            title: 'Pallet(s) removido(s) com sucesso!',
            message: `O(s) ${this.tableData.length} pallet(s) da posição ${this.position.coords} foram removidos com sucesso.`
          };
          const notificationId = '99';
          this.snackBarService.showFeedback(this.notificationTmpl, this.notificationData, notificationId);
          setTimeout(() => {
            this.snackBarService.hideNotification(notificationId);
          }, 5000);
          this.clearSidenavData();
          this.drawer.close();
          this.sidenavService.closeSidenav(true);
        } catch (e) {
          if (e.error.type === "GROUND_POSITION_TYPE_INVALID") {
            const message = `A posição escolhida não é uma posição de Saída`;
            this.errorService.openErrorSnackBarWithCustomMessage(message);
            this.selectedPosition.setErrors({ notUnique: true });
            return;
          }
          this.errorService.openErrorSnackBar(e);
        }
      }
    });
  }

  clearSidenavData() {
    this.tableData = [];
    this.selectedPosition.reset();
    this.position = null;
    this.positions = [];
    this.clearData = false;
  }

}
