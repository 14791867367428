import { Injectable } from '@angular/core';
import { ReportDataSourceService } from '../data-sources/report-data-source.service';
import { Subject, Observable } from 'rxjs';
import { InventoryReportStateEnum, IReport } from 'src/models/Report';
import { InventoryTypesEnum } from './action.service';
import { StockSortParams } from './stock.service';

export interface ReportsFilterParams {
  adminName?: string;
  type?: InventoryTypesEnum;
  state?: InventoryReportStateEnum;
  startDate?: number;
  endDate?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private $pageSize: Subject<number> = new Subject<number>();
  private $totalItems: Subject<number> = new Subject<number>();
  private $pageCount: Subject<number> = new Subject<number>();
  private $currentPage: Subject<number> = new Subject<number>();
  private $atLastPage: Subject<boolean> = new Subject<boolean>();
  private $atFirstPage: Subject<boolean> = new Subject<boolean>();
  private $items: Subject<any[]> = new Subject<any[]>();
  private pageSize = 30;
  private pageCount: number;
  public currentPage: number;
  private totalItems: number;
  private items: IReport[] = [];

  constructor(private dataSource: ReportDataSourceService) { }

  async init(filter = null, sortParams) {
    return this.getPage(1, filter, sortParams);
  }

  async setPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageCount = Math.ceil(this.totalItems / this.pageSize);
    this.$pageSize.next(this.pageSize);
    await this.getPage(1);
  }

  async reload(filter = null, sortParams?: StockSortParams) {
    return await this.getPage(this.currentPage, filter, sortParams);
  }

  async getNextPage(filter = null, sortParams: StockSortParams) {
    if (this.currentPage < this.pageCount) {
      const params = {};
      Object.assign(params, filter);
      Object.assign(params, sortParams);
      return await this.getPage(this.currentPage + 1, params);
    } else {
      throw Error('???');
    }
  }
  async getPreviousPage(filter = null, sortParams: StockSortParams) {
    if (this.currentPage > 1) {
      const params = {};
      Object.assign(params, filter);
      Object.assign(params, sortParams);
      return await this.getPage(this.currentPage - 1, params);
    } else {
      throw Error('???');
    }
  }

  private async getPage(page: number, filter: ReportsFilterParams = {}, sortParams?: StockSortParams) {
    this.currentPage = page;
    const params = {
      limit: this.pageSize,
      offset: (this.currentPage - 1) * this.pageSize
    };
    Object.assign(params, filter);
    Object.assign(params, sortParams);
    const response = await this.dataSource.getInventories(params);
    this.totalItems = response.count;
    this.items = response.values;
    this.pageCount = Math.ceil(this.totalItems / this.pageSize);
    this.$items.next(this.items);
    this.$pageCount.next(this.pageCount);
    this.$totalItems.next(this.totalItems);
    this.$currentPage.next(this.currentPage);
    this.$atLastPage.next(this.pageCount === 0 || this.currentPage === this.pageCount);
    this.$atFirstPage.next(this.currentPage === 1);
  }

  get currentPage$(): Observable<number> {
    return this.$currentPage;
  }
  get atFirstPage$(): Observable<boolean> {
    return this.$atFirstPage;
  }
  get atLastPage$(): Observable<boolean> {
    return this.$atLastPage;
  }
  get totalItems$(): Observable<number> {
    return this.$totalItems;
  }
  get pageCount$(): Observable<number> {
    return this.$pageCount;
  }
  get items$(): Observable<any[]> {
    return this.$items;
  }

}
