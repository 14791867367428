import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {SidebarService} from '../../core/services/sidebar.service';
import { UserModel } from 'src/app/core/data-sources/user-data-source.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {

  @Output() toggleSideBarEvent = new EventEmitter();
  @Input() user: UserModel;

  options = [
    {
      label: 'Painel de Processos',
      route: '/dashboard',
      sectionName: 'dashboard',
      icon: 'apps'
    },
    {
      label: 'Estoque',
      route: '/stock',
      sectionName: 'stock',
      icon: 'layer-group'
    },
    {
      label: 'Saída',
      route: '/exit',
      sectionName: 'exit',
      icon: 'sign-out-alt'
    },
    {
      label: 'Pallets com Endereço Desconhecido',
      route: '/unknown-address',
      sectionName: 'unknown-palce',
      icon: 'map-marker-question'
    },
    {
      label: 'Mapa do armazém',
      route: '/map',
      sectionName: 'map',
      icon: 'map'
    },
    {
      label: 'Gerenciamento de Tags',
      route: '/warehouse-map',
      sectionName: 'warehouse',
      icon: 'location-point'
    },
    {
      label: 'Separação de produtos',
      route: '/product-picking',
      sectionName: 'product-picking',
      icon: 'dropbox'
    },
    {
      label: 'Gerenciamento de produtos',
      route: '/product-management',
      sectionName: 'product-management',
      icon: 'box'
    },
    {
      label: 'Pedidos',
      // route: '/in',
      sectionName: '',
      icon: 'clipboard'
    },
    {
      label: 'Fornecedores',
      // route: '/in',
      sectionName: '',
      icon: 'store'
    },
    {
      label: 'Relatórios',
      route: '/reports',
      sectionName: 'reports',
      icon: 'clipboard'
    },
    {
      label: 'Usuários',
      route: '/register',
      sectionName: 'register',
      icon: 'users-alt'
    },
    {
      label: 'Setup',
      route: '/setup',
      sectionName: 'setup',
      icon: 'apps'
    },
    {
      label: 'Tarefas',
      route: '/tasks',
      sectionName: 'tasks',
      icon: 'clipboard-notes'
    },
  ];

  currentSectionSub;
  currentSectionName;
  toggle = false;

  constructor(private router: Router,
              private sidebarService: SidebarService) { }

  ngOnInit(): void {
    this.currentSectionSub = this.sidebarService.currentSection$.subscribe(currentSection => {
      if(currentSection != ''){
        this.currentSectionName = currentSection;
      }
    });
  }

  async navigateTo(route) {
    if (route === `/${this.currentSectionName}`) {
      this.currentSectionName = route.substr(1);
      this.toggle = !this.toggle;
    } else {
      await this.router.navigate([route]);
      this.toggle = false;
    }
    this.toggleSideBarEvent.emit();
  }
}
