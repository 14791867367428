    <app-table-header-column name="date" size="small">
      <span content>
        Entrada
        <app-filter-button
          [column]="'createdAt'"
          [menu]="createdAtFilterMenu"
          [appliedFilters]="appliedFilters"
          [sortColumn]="sortColumn"
          #createdAtFilterBtn>
        </app-filter-button>
      </span>
    </app-table-header-column>

    <mat-menu #createdAtFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por data de entrada</h4>
        <button (click)="sort('createdAt', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('createdAt', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'asc'"></i>
        </button>
        <form [formGroup]="dateFiltersForm">
          <mat-form-field appearance="outline">
            <mat-label>Data inicial</mat-label>
            <input matInput [matDatepicker]="startDatePicker" formControlName="start" [max]="maxDate" disabled>
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Data final</mat-label>
            <input matInput [matDatepicker]="endDatePicker" formControlName="end" [max]="maxDate" disabled>
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <section class="actions">
            <button mat-stroked-button class="gray-1" (click)="clearFilters(createdAtFilterBtn.menuTrigger, 'createdAt')">
              Limpar
            </button>
            <button mat-raised-button class="primary-1" (click)="applyFilters(createdAtFilterBtn.menuTrigger)">Salvar</button>
          </section>
        </form>
      </div>
    </mat-menu>

    <app-table-header-column name="date" size="small">
      <span content>
        Últ movimentação
        <app-filter-button
          [column]="'lastMovedAt'"
          [menu]="lastMovedAtFilterMenu"
          [appliedFilters]="appliedFilters"
          [sortColumn]="sortColumn"
          #lastMovedAtFilterBtn>
        </app-filter-button>
      </span>
    </app-table-header-column>

    <mat-menu #lastMovedAtFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Ordenar por última movimentação</h4>
        <button (click)="sort('lastMovedAt', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['lastMovedAt'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('lastMovedAt', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['lastMovedAt'] && sortDirection == 'asc'"></i>
        </button>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters(lastMovedAtFilterBtn.menuTrigger, 'lastMovedAt')">
            Limpar
          </button>
          <button mat-raised-button class="primary-1" (click)="applyFilters(lastMovedAtFilterBtn.menuTrigger)">Salvar</button>
        </section>
      </div>
    </mat-menu>

    <app-table-header-column name="section" size="small">
      <span content>
        Setor
        <app-filter-button
          [column]="'section'"
          [menu]="sectionFilterMenu"
          [appliedFilters]="appliedFilters"
          [sortColumn]="sortColumn"
          #sectionFilterBtn>
        </app-filter-button>
      </span>
    </app-table-header-column>

    <mat-menu #sectionFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por setor</h4>
        <button (click)="sort('section', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['section'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('section', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['section'] && sortDirection == 'asc'"></i>
        </button>
          <mat-form-field appearance="outline">
            <input matInput
                  placeholder="Setor"
                  [formControl]="sectionFormControl"
                  autocomplete="off">
          </mat-form-field>
          <section class="actions">
            <button mat-stroked-button class="gray-1" (click)="clearFilters(sectionFilterBtn.menuTrigger, 'section')">
              Limpar
            </button>
            <button mat-raised-button class="primary-1" (click)="applyFilters(sectionFilterBtn.menuTrigger)">Salvar</button>
          </section>
      </div>
    </mat-menu>

    <app-table-header-column name="position" size="small">
      <span content>
        Coordenada
        <app-filter-button
          [column]="'position'"
          [menu]="positionFilterMenu"
          [appliedFilters]="appliedFilters"
          [sortColumn]="sortColumn"
          #positionFilterBtn>
        </app-filter-button>
      </span>
    </app-table-header-column>

    <mat-menu #positionFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por coordenada</h4>
        <button (click)="sort('position', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['position'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('position', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['position'] && sortDirection == 'asc'"></i>
        </button>
        <mat-form-field appearance="outline">
          <input matInput
                placeholder="Coordenada"
                [formControl]="positionFormControl"
                autocomplete="off">
          <mat-error>Coordenada inválida</mat-error>
        </mat-form-field>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters(positionFilterBtn.menuTrigger, 'position')">
            Limpar
          </button>
          <button mat-raised-button class="primary-1" (click)="applyFilters(positionFilterBtn.menuTrigger)">Salvar</button>
        </section>
      </div>
    </mat-menu>

    <app-table-header-column name="alias" size="small">
      <span content>
        Posição
        <app-filter-button
          [column]="'alias'"
          [menu]="aliasFilterMenu"
          [appliedFilters]="appliedFilters"
          [sortColumn]="sortColumn"
          #aliasFilterBtn>
        </app-filter-button>
      </span>
    </app-table-header-column>

    <mat-menu #aliasFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por posição</h4>
        <button (click)="sort('alias', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['alias'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('alias', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['alias'] && sortDirection == 'asc'"></i>
        </button>
        <mat-form-field appearance="outline">
          <input matInput
                 placeholder="Posição"
                 [formControl]="aliasFormControl"
                 autocomplete="off">
          <mat-error>Posição inválida</mat-error>
        </mat-form-field>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters(aliasFilterBtn.menuTrigger, 'alias')">
            Limpar
          </button>
          <button mat-raised-button class="primary-1" (click)="applyFilters(aliasFilterBtn.menuTrigger)">Salvar</button>
        </section>
      </div>
    </mat-menu>

    <app-table-header-column name="levelName" size="smallest">
      <span content>
        Nível
        <app-filter-button
          [column]="'levelName'"
          [menu]="levelNameFilterMenu"
          [appliedFilters]="appliedFilters"
          [sortColumn]="sortColumn"
          #levelFilterBtn>
        </app-filter-button>
      </span>
    </app-table-header-column>

    <mat-menu #levelNameFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por nível</h4>
        <button (click)="sort('levelName', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['levelName'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('levelName', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['levelName'] && sortDirection == 'asc'"></i>
        </button>
          <mat-form-field appearance="outline">
            <input matInput
                   placeholder="Nível"
                   [formControl]="levelNameFormControl"
                   autocomplete="off">
          </mat-form-field>
          <section class="actions">
            <button mat-stroked-button class="gray-1" (click)="clearFilters(levelFilterBtn.menuTrigger, 'levelName')">
              Limpar
            </button>
            <button mat-raised-button class="primary-1" (click)="applyFilters(levelFilterBtn.menuTrigger)">Salvar</button>
          </section>
      </div>
    </mat-menu>

    <app-table-header-column name="height" size="smallest">
      <span content>
        Altura
        <app-filter-button
          [column]="'z'"
          [menu]="heightFilterMenu"
          [appliedFilters]="appliedFilters"
          [sortColumn]="sortColumn"
          #heightFilterBtn>
        </app-filter-button>
      </span>
    </app-table-header-column>

    <mat-menu #heightFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Ordenar por altura</h4>
        <button (click)="sort('z', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['z'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('z', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['z'] && sortDirection == 'asc'"></i>
        </button>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters(heightFilterBtn.menuTrigger, 'z')">
            Limpar
          </button>
          <button mat-raised-button class="primary-1" (click)="applyFilters(heightFilterBtn.menuTrigger)">Salvar</button>
        </section>
      </div>
    </mat-menu>

    <app-table-header-column name="product-code" size="small">
      <span content>
        Código de produto
        <app-filter-button
          [column]="'sku'"
          [menu]="productSkuFilterMenu"
          [appliedFilters]="appliedFilters"
          [sortColumn]="sortColumn"
          #skuFilterBtn>
        </app-filter-button>
      </span>

    </app-table-header-column>

    <mat-menu #productSkuFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por código de produto</h4>
        <button (click)="sort('sku', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['sku'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('sku', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['sku'] && sortDirection == 'asc'"></i>
        </button>
          <mat-form-field appearance="outline">
            <input matInput
                   placeholder="Código do produto"
                   [formControl]="productSkuFormControl"
                   autocomplete="off">
          </mat-form-field>
          <section class="actions">
            <button mat-stroked-button class="gray-1" (click)="clearFilters(skuFilterBtn.menuTrigger, 'sku')">
              Limpar
            </button>
            <button mat-raised-button class="primary-1" (click)="applyFilters(skuFilterBtn.menuTrigger)">Salvar</button>
          </section>
      </div>
    </mat-menu>

    <app-table-header-column name="description" size="medium">
      <span content>Descrição</span>
    </app-table-header-column>

    <app-table-header-column name="quantity" size="smallest">
      <span content>
        Qtd
        <app-filter-button
          [column]="'quantity'"
          [menu]="quantityFilterMenu"
          [appliedFilters]="appliedFilters"
          [sortColumn]="sortColumn"
          #quantityFilterBtn>
        </app-filter-button>
      </span>
    </app-table-header-column>

    <mat-menu #quantityFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por quantidade</h4>
        <button (click)="sort('quantity', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['quantity'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('quantity', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['quantity'] && sortDirection == 'asc'"></i>
        </button>
        <section class="quantity">
          <p>QUANTIDADE</p>
          <div class="counter">
            <div class="operator-button" (click)="changeQuantity('MINUS')">
              <i class="uil uil-minus"></i>
            </div>
            <span>{{ quantityCount }}</span>
            <div class="operator-button" (click)="changeQuantity('PLUS')">
              <i class="uil uil-plus"></i>
            </div>
          </div>
        </section>
        <section class="actions">
          <button mat-stroked-button class="gray-1"
            (click)="clearFilters(quantityFilterBtn.menuTrigger, 'quantity')">
            Limpar
          </button>
          <button mat-raised-button class="primary-1" (click)="applyFilters(quantityFilterBtn.menuTrigger)">Salvar</button>
        </section>
      </div>
    </mat-menu>

    <app-table-header-column name="state" size="small">
      <span content>
        Estado
        <app-filter-button
          [column]="'state'"
          [menu]="stateFilterMenu"
          [appliedFilters]="appliedFilters"
          [sortColumn]="sortColumn"
          #stateFilterBtn>
        </app-filter-button>
      </span>
    </app-table-header-column>

    <mat-menu #stateFilterMenu="matMenu">
      <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <h4>Filtrar por estado</h4>
        <button (click)="sort('state', 'desc')" mat-menu-item>
          Ordem decrescente
          <i class="uil uil-check" *ngIf="showCheckIcon['state'] && sortDirection == 'desc'"></i>
        </button>
        <button (click)="sort('state', 'asc')" mat-menu-item>
          Ordem crescente
          <i class="uil uil-check" *ngIf="showCheckIcon['state'] && sortDirection == 'asc'"></i>
        </button>
        <mat-form-field class="dropdown" appearance="outline">
          <mat-label>Estado</mat-label>
          <mat-select [(value)]="stateValue">
            <mat-option *ngFor="let option of stateOptions" [value]="option.value">{{ option.label }}</mat-option>
          </mat-select>
        </mat-form-field>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters(stateFilterBtn.menuTrigger, 'state')">
            Limpar
          </button>
          <button mat-raised-button class="primary-1" (click)="applyFilters(stateFilterBtn.menuTrigger)">Salvar</button>
        </section>
      </div>
    </mat-menu>
