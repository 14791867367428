import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { getCoordinates } from 'src/app/helpers/format-position';
import { CrateStateEnum } from 'src/models/Crate';
import { StockFilterParams, StockSortParams } from 'src/app/core/services/stock.service';
import * as moment from 'moment';
import { MatMenuTrigger } from '@angular/material/menu';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};
@Component({
  selector: 'app-stock-header-with-level',
  templateUrl: './stock-header-with-level.component.html',
  styleUrls: ['./stock-header-with-level.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class StockHeaderWithLevelComponent implements OnInit {
  @Input() valuesApplied;
  @Output() valuesToFilter = new EventEmitter();
  maxDate = new Date();
  dateFiltersForm = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null)
  });
  productSkuFormControl = new FormControl(null);
  levelNameFormControl = new FormControl(null);
  sectionFormControl = new FormControl(null);
  positionFormControl = new FormControl(null, Validators.pattern(/^[A-Za-z]{1,3}\d{1,7}$/));
  aliasFormControl = new FormControl(null);
  showCheckIcon = {};
  appliedFilters = {};
  sortDirection = '';
  sortParams: StockSortParams;
  quantityCount = null;
  stateValue = null;
  filterParams: StockFilterParams;
  sortColumn = '';

  stateOptions: { label: string; value: CrateStateEnum }[] = [
    { label: 'Armazenado', value: CrateStateEnum.STORED },
    { label: 'Armazenado e divergente', value: CrateStateEnum.STORED_DIVERGENT },
    { label: 'Posição errada', value: CrateStateEnum.WRONG_POSITION },
    { label: 'Aguardando armazenamento', value: CrateStateEnum.AWAITING_STORAGE },
    { label: 'Aguardando separação', value: CrateStateEnum.AWAITING_PICKING },
    { label: 'Aguardando movimentação', value: CrateStateEnum.AWAITING_MOVING },
    { label: 'Movimentando', value: CrateStateEnum.MOVING },
    { label: 'Deslocado', value: CrateStateEnum.MISPLACED },
    { label: 'Deslocado e divergente', value: CrateStateEnum.MISPLACED_DIVERGENT },
    { label: 'Perdido', value: CrateStateEnum.MISSING },
    { label: 'Reservado', value: CrateStateEnum.RESERVED }
  ];

  constructor() { }

  ngOnInit(): void {
    this.setAppliedValues();
  }

  setAppliedValues() {
    if (this.valuesApplied?.quantity) {
      this.quantityCount = this.valuesApplied.quantity;
    }
    if (this.valuesApplied?.sku) {
      this.productSkuFormControl.setValue(this.valuesApplied.sku);
    }
    if (this.valuesApplied?.state) {
      this.stateValue = this.valuesApplied.state;
    }
  }

  applyFilters(menu: MatMenuTrigger) {
    const startDate = this.dateFiltersForm.value.start ? moment(this.dateFiltersForm.value.start._d).valueOf() : null;
    const endDate = this.dateFiltersForm.value.end ? moment(this.dateFiltersForm.value.end._d).endOf('day').valueOf() : null;
    let x = null;
    let y = null;
    if (this.positionFormControl.value) {
      x = getCoordinates(this.positionFormControl.value.toUpperCase()).x;
      y = getCoordinates(this.positionFormControl.value.toUpperCase()).y - 1;
    }
    this.filterParams = {
      startDate: startDate,
      endDate: endDate,
      quantity: this.quantityCount,
      state: this.stateValue,
      section: this.sectionFormControl.value,
      levelName: this.levelNameFormControl.value,
      sku: this.productSkuFormControl.value,
      alias: this.aliasFormControl.value,
      x,
      y
    }
    this.removeNullKeys(this.filterParams);
    this.sortColumn = this.sortParams?.orderBy;
    this.valuesToFilter.emit({
      filterParams: Object.keys(this.filterParams).some(key => this.filterParams[key]) ? this.filterParams : null,
      sortParams: this.sortParams
    });
    menu.closeMenu();
  }

  sort(column: string, direction: string) {
    this.sortParams = null;
    this.showCheckIcon = {};
    this.sortDirection = direction;
    this.showCheckIcon[column] = true;
    this.sortParams = {
      orderBy: column,
      sortBy: direction.toUpperCase()
    };
  }

  clearFilters(menu: MatMenuTrigger, filter: string) {
    this.sortColumn = this.sortColumn === filter ? null : this.sortColumn;
    this.sortParams = this.sortParams?.orderBy === filter ? null : this.sortParams;
    delete this.appliedFilters[filter];
    delete this.showCheckIcon[filter];
  
    switch (filter) {
      case 'quantity':
        this.quantityCount = null;
        delete this.filterParams['quantity'];
        break;
      case 'createdAt':
        this.dateFiltersForm.reset();
        delete this.filterParams['endDate'];
        delete this.filterParams['startDate'];
        break;
      case 'sku':
        this.productSkuFormControl.reset();
        delete this.filterParams['sku'];
        break;
      case 'levelName':
        this.levelNameFormControl.reset();
        delete this.filterParams['levelName'];
        break;
      case 'section':
        this.sectionFormControl.reset();
        delete this.filterParams['section'];
        break;
      case 'position':
        this.positionFormControl.reset();
        delete this.filterParams['x'];
        delete this.filterParams['y'];
        break;
      case 'alias':
        this.aliasFormControl.reset();
        delete this.filterParams['alias'];
        break;
      case 'state':
        this.stateValue = null;
        delete this.filterParams['state'];
        break;
    }

    this.valuesToFilter.emit({
      filterParams: this.filterParams,
      sortParams: this.sortParams
    });
    menu.closeMenu();
  }

  changeQuantity(operator) {
    if (operator === 'PLUS') {
      return this.quantityCount++;
    } else if (operator === 'MINUS' && this.quantityCount > 0) {
      return this.quantityCount--;
    }
  }

  removeNullKeys(params) {
    Object.keys(params).forEach(key => {
      if (params[key] === null) {
        delete params[key];
      } else {
        this.setAppliedFilters(key);
      }
    });
  }

  setAppliedFilters(name) {
    switch (name) {
      case 'startDate':
      case 'endDate':
        this.appliedFilters['createdAt'] = true;
        break;
      case 'x':
      case 'y':
        this.appliedFilters['position'] = true;
        break;
      default:
        this.appliedFilters[name] = true;
        break;
    }
  }

}
