<app-header-modal [color]="'secondary-2'" title="Dados do Relatório">
  <div class="status-header" status>
    <app-status-header-modal
      [icon]="report.state === 'APPROVED' ? 'check' : report.state === 'REJECTED' ? 'times' : 'spinner-alt'"
      [status]="translateState(report.state)"
      [color]="report.state === 'APPROVED' ? 'success' : report.state === 'REJECTED' ? 'alert' : 'warning'">
    </app-status-header-modal>
    <i class="uil uil-times" (click)="close('CANCELED')"></i>
  </div>
</app-header-modal>

<mat-tab-group mat-align-tabs="center" *ngIf="!removePallets">
  <mat-tab label="Relatório">
    <div class="infos">
      <app-info-oc-or-nfe label="Data de emissão" [value]="formatDate(report.createdAt) || '---'"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Realizado por"
        [value]="getUserName(report.user)">
      </app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Requisitado por"
        [value]="getUserName(report.admin)">
      </app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Tipo de inventário"
        [value]="translateTypeName(report.data.type)">
      </app-info-oc-or-nfe>
      <app-info-oc-or-nfe *ngIf="reportData.type === inventoryTypesEnum.INVENTORY_BY_PRODUCT" label="Nome do produto"
        [value]="productName || '---'">
      </app-info-oc-or-nfe>
      <app-info-oc-or-nfe *ngIf="reportData.type === inventoryTypesEnum.INVENTORY_BY_GROUND_POSITION_GROUP"
        label="Setor" [value]="formattedGroundPositionGroupName || '---'">
      </app-info-oc-or-nfe>
      <app-info-oc-or-nfe *ngIf="reportData.type === inventoryTypesEnum.INVENTORY_BY_GROUND_POSITION"
        label="Posição" [value]="groundPositionName || '---'">
        </app-info-oc-or-nfe>
      <app-info-oc-or-nfe *ngIf="reportData.type === inventoryTypesEnum.INVENTORY_BY_PRODUCT" label="SKU"
        [value]="reportData?.target?.skus?.[0] || '---'">
      </app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Total de itens" [value]="totalItems"></app-info-oc-or-nfe>
    </div>

    <ng-container>
      <div class="table pallet-inventory" *ngIf="reportData?.crate">
        <app-pallet-details-modal [reportData]="reportData"></app-pallet-details-modal>
        <app-pallet-reports-table [items]="items"></app-pallet-reports-table>
      </div>

      <div class="table others-inventory" *ngIf="!reportData?.crate">
        <app-reports-table-modal [items]="items" [report]="report"></app-reports-table-modal>
      </div>

      <div class="buttons">
        <button mat-stroked-button class="gray-2" (click)="close('CANCELED')">cancelar</button>
        <button mat-raised-button class="alert" (click)="close('REJECTED')"
          [disabled]="report.state !== 'AWAITING_ADMIN_APPROVAL'">rejeitar</button>
        <button mat-raised-button class="primary-1" (click)="close('APPROVED')"
          [disabled]="report.state !== 'AWAITING_ADMIN_APPROVAL'">confirmar</button>
      </div>
    </ng-container>
  </mat-tab>
</mat-tab-group>
<section *ngIf="removePallets" class="missing-pallets-section">
  <!-- TODO use app-material-generic-table component -->
  <p>Os seguintes pallets serão removidos do armazém:</p>
  <table mat-table [dataSource]="palletsToRemove" class="missing-pallets-table">
    <ng-container matColumnDef="rfid">
      <th mat-header-cell class="header" *matHeaderCellDef>Tag RFID</th>
      <td mat-cell *matCellDef="let element">{{element.tagRfid}}</td>
    </ng-container>

    <ng-container matColumnDef="position">
      <th mat-header-cell class="header" *matHeaderCellDef>Posição</th>
      <td mat-cell *matCellDef="let element">{{ toExcelFormat(element.x, element.y) || '---' }}</td>
    </ng-container>

    <ng-container matColumnDef="sku">
      <th mat-header-cell class="header" *matHeaderCellDef>Código do produto</th>
      <td mat-cell *matCellDef="let element">
        <span *ngFor="let item of element.skuGroups">
          {{item.sku}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell class="header" *matHeaderCellDef>Descrição</th>
      <td mat-cell *matCellDef="let element">
        <span *ngFor="let item of element.skuGroups">
          {{item.name}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell class="header" *matHeaderCellDef>Quantidade</th>
      <td mat-cell *matCellDef="let element">
        <span *ngFor="let item of element.skuGroups">
          {{item.quantity}}
        </span>
      </td>
    </ng-container>

    <tr mat-header-row class="header-container" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="item-container" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="buttons">
    <button mat-stroked-button class="gray-2" (click)="removePallets = false">cancelar</button>
    <button mat-raised-button
      class="alert"
      (click)="confirmRemovePallets()">
      excluir
    </button>
  </div>
</section>