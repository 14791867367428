<app-header-modal [color]="'secondary-2'" title="Dados do Pallet">
  <div class="status-header" status>
    <app-status-header-modal [icon]="'check'" [color]="'success'" [status]="'Conferido'"></app-status-header-modal>
    <i class="uil uil-times" (click)="close()"></i>
  </div>
</app-header-modal>

<mat-tab-group mat-align-tabs="center" [selectedIndex]="selected.value"
  (selectedIndexChange)="onSelectedIndexChange($event)">
  <mat-tab label="Pallet">
    <app-pallet-details
      [isExit]="false"
      [crateDetails]="data.crateDetails">
    </app-pallet-details>
  </mat-tab>
  <mat-tab label="Histórico">
    <app-movement-details [crateId]="data.item.crateId" *ngIf="loadHistoryTab"></app-movement-details>
  </mat-tab>
  <mat-tab label="Detalhes dos produtos">
    <app-product-image-tab [products]="products" *ngIf="loadProductTab"></app-product-image-tab>
  </mat-tab>
  <mat-tab label="Comentários">
    <app-in-details-comments [itemDetails]="data.crateDetails"
                             (submitEvent)="submitComment($event)"
                             (deleteEvent)="deleteComment($event)"></app-in-details-comments>
  </mat-tab>
</mat-tab-group>
