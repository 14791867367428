import { Component, Input, OnInit } from '@angular/core';
import { IEventHistory, EventType } from '../../../../models/History';
import { toExcelFormat } from '../../../helpers/format-position';
import centimetersToMeters from '../../../helpers/centimeters-to-meters';
import { getTaskTypeName, getEventTypeName } from '../../../helpers/format-task-type';
import { EventHistoryService } from '../../../core/data-sources/event-history.service';
import { trigger, state, animate, style, transition } from '@angular/animations';
import { TaskType } from 'src/models/History';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-movement-details',
  templateUrl: './movement-details.component.html',
  styleUrls: ['./movement-details.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class MovementDetailsComponent implements OnInit {
  @Input() crateId: string;
  @Input() title: string;
  @Input() warning: string | null = null;
  displayedColumns = ['action', 'type', 'taskType', 'user', 'deviceName', 'coords', 'position', 'levelName', 'height', 'movedAt'];
  columnsDefinition = [
    { key: "type", text: "Tipo de evento" },
    { key: "taskType", text: "Tarefa" },
    { key: "user", text: "Usuário" },
    { key: "deviceName", text: "Aparelho" },
    { key: "coords", text: "Coordenada" },
    { key: "position", text: "Posição" },
    { key: "levelName", text: "Nível" },
    { key: "height", text: "Altura" },
    { key: "movedAt", text: "Data do evento" },
  ];
  productsTableDisplayedColumns = ['sku', 'name', 'quantity'];
  productsTableColumnDefinition = [
    { key: 'sku', text: "SKU" },
    { key: 'name', text: "Nome" },
    { key: 'quantity', text: 'Quantidade', alignRight: true }
  ];
  dataMovement: IEventHistory[] = [];
  tableData: unknown[] = []; //TODO add type
  expandedElement: IEventHistory | null;
  isLoading = true;

  constructor(private eventHistoryService: EventHistoryService) { }

  ngOnInit(): void {
    this.getMovementHistory();
  }

  async getMovementHistory(): Promise<void> {
    this.dataMovement = await this.eventHistoryService.getEventHistory(this.crateId);
    this.formatData(this.dataMovement);
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  formatPosition(x: number, y: number): string {
    return toExcelFormat(x, y);
  }

  translateEventType(type: EventType): string {
    return getEventTypeName(type);
  }

  showUsername(user): string {
    if (!user) {
      return '---';
    }

    return user.firstName + ' ' + user.lastName;
  }

  timestampToDate(timestamp: number): string {
    return timestamp ? moment(timestamp).format('DD/MM/YYYY HH:mm:ss.SSS') : '---';
  }

  translateTaskType(taskType: TaskType) {
    return getTaskTypeName(taskType);
  }

  formatData(data: IEventHistory[]) {
    this.tableData = data.map((element) => {
      return {
        height: element.z >= 0 ? centimetersToMeters(element.z) + ' metros' : 'Conflitante',
        levelName: element.groundPositionGroupLevelName || '---',
        taskType: this.translateTaskType(element.taskType),
        movedAt: this.timestampToDate(element.timestamp),
        coords: this.formatPosition(element.x, element.y),
        position: element.groundPositionAlias || '---',
        user: this.showUsername(element.user),
        deviceName: element.deviceName || '---',
        type: this.translateEventType(element.type),
        enableExpansion: element.type === EventType.EDIT_PRODUCT,
        previousSkuGroups: element.previousSkuGroups ?? [],
        currentSkuGroups: element.currentSkuGroups ?? [],
        id: element.id
      }
    });
  }

}
