import { translateType } from "src/models/Report";
import { toExcelFormat } from "./format-position";

export function getGroupOrPositionName(reportData, match: "groundPositions" | "groundPositionGroups", key: "name" | "alias") {
  if (reportData?.target?.[match]?.length) {
    const arr = reportData?.target?.[match];
    let groupedNames = [];
    for (const group of arr) {
      if (key === 'alias' && group[key] === null) {
        group[key] = `--- (coordenada: ${toExcelFormat(group.x, group.y)})`;
      }
      group[key] === undefined ? groupedNames : groupedNames.push(group[key]);
    }
    const formattedNames = groupedNames.length > 1 ? groupedNames.join(" / ") : groupedNames;
    return formattedNames.length ? `${translateType(reportData.type)} - ${formattedNames}` : translateType(reportData.type);
  } else {
    return translateType(reportData.type);
  }
}