<div class="page-header">
  <h1>Conferência física</h1>
  <app-prev-next-button (click)="toStocking()" direction="right" label="estoque"></app-prev-next-button>
</div>

<div class="ctrl-bar">
  <div class="update-button">
    <p>Atualizado às {{actualHour}}</p>
    <button mat-raised-button (click)="update()">
      <i class="uil uil-sync"></i>
      <span>Atualizar</span>
    </button>
  </div>
</div>

<app-side-color-card color="in">
  <div content class="table">
    <div class="table-top">
      <h2>ÍTENS EM CONFERÊNCIA FÍSICA ({{ paginationService.totalItems$ | async }})</h2>
      <app-paginator
        [currentPage]="paginationService.currentPage$ | async"
        [pageCount]="paginationService.pageCount$ | async"
        [atFirstPage]="paginationService.atFirstPage$ | async"
        [atLastPage]="paginationService.atLastPage$ | async"
        (previousPage)="getPreviousPage($event)"
        [blockButton]="blockPaginatorButton"
        (nextPage)="getNextPage($event)">
      </app-paginator>
    </div>
    <div class="header" *ngIf="filteredGoods?.length !== 0">
      <app-table-header-column name="check" (click)="selectAllGoods()" styleType="old">
        <span content>
          <mat-checkbox [checked]="allGoodsIsChecked()" (click)="$event.preventDefault()"></mat-checkbox>
        </span>
      </app-table-header-column>

      <app-table-header-column name="date" styleType="old">
        <span content>Data de entrada</span>
      </app-table-header-column>
      
      <app-table-header-column name="position" styleType="old">
        <span content>Posição</span>
      </app-table-header-column>

      <app-table-header-column name="user" styleType="old">
        <span content>Usuário</span>
      </app-table-header-column>

      <app-table-header-column name="product" styleType="old">
        <span content>Código do produto</span>
      </app-table-header-column>

      <app-table-header-column name="product" styleType="old">
        <span content>Nome do produto</span>
      </app-table-header-column>

      <app-table-header-column name="quantity" styleType="old">
        <span content>Qtd</span>
      </app-table-header-column>

      <app-table-header-column name="messages" styleType="old">
        <span content>Comentários</span>
      </app-table-header-column>

      <app-table-header-column name="rfid" styleType="old">
        <span content>
          <img src="assets/icons/rfid.svg">
          Tag RFID
        </span>
      </app-table-header-column>
    </div>

    <ng-container *ngIf="state !== InspectionPageState.LOADING">
      <div class="item"
           *ngFor="let good of filteredGoods; let odd = odd"
           [ngClass]="{'background-opacity': odd}">

        <app-table-column name="check" (click)="selectGood(good)" styleType="old">
          <div content>
            <mat-checkbox
              *ngIf="good.palletState === PalletState.RECEIVED"
              [checked]="selectedGoodsTagRfids.has(good.tagRfid)"
              (click)="$event.preventDefault()">
            </mat-checkbox>
          </div>
        </app-table-column>

        <app-table-column name="date"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          styleType="old">
          <div content class="quantity-wrapper">
            {{formatTimestamp(good.entryTimestamp)}}
          </div>
        </app-table-column>

        <app-table-column name="position"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          styleType="old">
          <div content class="quantity-wrapper">
            {{getGroundPositionAlias(good)}}
          </div>
        </app-table-column>

        <app-table-column name="user"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          styleType="old">
          <div content class="quantity-wrapper">
            {{getUserName(good.user)}}
          </div>
        </app-table-column>

        <app-table-column name="product"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          styleType="old">
          <div content class="product-name-wrapper">
            <span *ngFor="let product of good.products">{{ product.sku }}</span>
          </div>
        </app-table-column>

        <app-table-column name="product"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          styleType="old">
          <div content class="product-name-wrapper">
            <span *ngFor="let product of good.products">{{ product.name }}</span>
          </div>
        </app-table-column>

        <app-table-column name="quantity"
                          (click)="openProductDetailsModal(good, $event, 0)"
                          styleType="old">
          <div content class="quantity-wrapper">
            <span *ngFor="let product of good.products">{{ product.quantity }}</span>
          </div>
        </app-table-column>

        <app-table-column name="messages" (click)="openProductDetailsModal(good, $event, 4)" styleType="old">
          <span content>
            <i *ngIf="!good.commentsQty" class="uil uil-comment"></i>
            <i *ngIf="good.commentsQty" class="uil uil-comment-lines"></i>
            {{ good.commentsQty }}
          </span>
        </app-table-column>

        <app-table-column name="rfid"
                          (click)="good.palletStatus === 'MISSING' ? openProductDetailsModal(good, $event, 0) : null"
                          styleType="old">
          <div content>
            <span [ngClass]="{'bin-padding': good.palletType === 'BIN', 'hide-element': good.palletStatus === 'MISSING'}">
              {{ checkPalletType(good.palletType) }}
            </span>
            <button mat-raised-button class="primary-2"
                    (click)="openTaskRequest(good)"
                    [ngClass]="{'white': good.palletState === 'AWAITING_STORAGE', 'hide-element': good.palletStatus === 'MISSING'}">

              <img *ngIf="good.palletState === 'AWAITING_STORAGE'" src="assets/icons/forklift-1.svg">
              <img *ngIf="good.palletState === 'RECEIVED'" src="assets/icons/forklift-2.svg">
              {{ getButtonText(good) }}
            </button>
          </div>
        </app-table-column>
      </div>
    </ng-container>

    <div class="loader" *ngIf="state === InspectionPageState.LOADING">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <div class="empty" *ngIf="filteredGoods?.length === 0">
      NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
    </div>
  </div>
</app-side-color-card>

<app-full-page-loader *ngIf="isLoadingModal"></app-full-page-loader>
