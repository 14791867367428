<ng-container>
  <app-table-column name="tagRfid" size="medium">
    <span content>{{ item.tagRfid || '---' }}</span>
  </app-table-column>
  <app-table-column name="expected-position" size="small">
    <span content>{{ expectedPosition || '---' }}</span>
  </app-table-column>
  <app-table-column name="found-position" size="small">
    <span content>{{ foundPosition || '---' }}</span>
  </app-table-column>
  <app-table-column name="expected-height" size="small">
    <span content>{{ expectedLevel }}</span>
  </app-table-column>
  <app-table-column name="found-height" size="small">
    <span content>{{ foundLevel }}</span>
  </app-table-column>
  <app-table-column name="status" size="medium">
    <span content>
      <div class="status-icon-wrapper">
        <app-background-icon [backgroundColor]="iconBackgroundColor" [icon]="icon"></app-background-icon>
        {{ crateState || "---" }}
      </div>
    </span>
  </app-table-column>
  <app-table-column name="action" size="small" *ngIf="report.state === 'AWAITING_ADMIN_APPROVAL' && isMissingPallets">
    <span content>
      <mat-checkbox color="primary" *ngIf="crateState === 'Perdido' " (change)="onCheckboxChange(item)"></mat-checkbox>
    </span>
  </app-table-column>
</ng-container>

