<div class="wrapper">
  <div class="upper-content">
    <div class="column">
      <app-info-oc-or-nfe label="Tag RFID" [value]="crate.tagRfid"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Entrada" [value]="formatDate(crate.createdAt)"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Última movimentação" [value]="formatDate(crate.lastMovedAt)"></app-info-oc-or-nfe>
    </div>
    <div class="column">
      <app-info-oc-or-nfe label="Último setor" [value]="crate.section || '---'"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Última posição" [value]="crate.alias || '---'"></app-info-oc-or-nfe>
    </div>
    <div class="column">
      <app-info-oc-or-nfe label="Inventário ativo" [value]="crate.activeInventoryCount" [isStatus]="true"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Total de inventários" [value]="crate.inventoryCount"></app-info-oc-or-nfe>
    </div>
  </div>
  <div class="lower-content">
    <app-material-generic-table
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [columnsDefinition]="columnsDefinition">
    </app-material-generic-table>
  </div>
</div>
