import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { getCoordinates } from '../../../helpers/format-position';
import { CrateStateEnum } from '../../../../models/Crate';
import { StockFilterParams, StockSortParams } from '../../../core/services/stock.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { DEFAULT_DATE_FORMAT } from '../../../helpers/date-format.helper';
import * as moment from 'moment';

@Component({
  selector: 'app-exit-table-header',
  templateUrl: './exit-table-header.component.html',
  styleUrls: ['./exit-table-header.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DEFAULT_DATE_FORMAT }]
})
export class ExitTableHeaderComponent implements OnInit {
  @Output() filterValues = new EventEmitter();
  maxDate = new Date();
  showCheckIcon = {};
  appliedFilters = {};
  sortDirection = '';
  sortParams: StockSortParams;
  filterParams: StockFilterParams;
  sortColumn = '';
  form = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    sku: new FormControl(null),
    quantity: new FormControl(null),
    section: new FormControl(null),
    alias: new FormControl(null),
    position: new FormControl(null, Validators.pattern(/^[A-Za-z]{1,3}\d{1,7}$/)),
    levelName: new FormControl(null)
  });

  constructor() { }

  ngOnInit(): void {
  }

  applyFilters(menu: MatMenuTrigger) {
    let x = null;
    let y = null;
    if (this.form.value.position) {
      x = getCoordinates(this.form.value.position.toUpperCase()).x;
      y = getCoordinates(this.form.value.position.toUpperCase()).y - 1;
    }
    const startDate = this.form.value.startDate ? moment(this.form.value.startDate._d).valueOf() : null;
    const endDate = this.form.value.endDate ? moment(this.form.value.endDate._d).endOf('day').valueOf() : null;

    this.filterParams = {
      startDate: startDate,
      endDate: endDate,
      quantity: this.form.value.quantity,
      sku: this.form.value.sku,
      alias: this.form.value.alias,
      section: this.form.value.section,
      levelName: this.form.value.levelName,
      x,
      y
    };

    this.removeNullKeys(this.filterParams);
    this.sortColumn = this.sortParams?.orderBy;
    this.filterValues.emit({
      filterParams: this.filterParams,
      sortParams: this.sortParams
    });
    menu.closeMenu();
  }

  sort(column: string, direction: string) {
    this.sortParams = null;
    this.showCheckIcon = {};
    this.sortDirection = direction;
    this.showCheckIcon[column] = true;
    this.sortParams = {
      orderBy: column,
      sortBy: direction
    };
  }

  clearFilters(menu: MatMenuTrigger, filter: string) {
    this.sortColumn = this.sortColumn === filter ? null : this.sortColumn;
    this.sortParams = this.sortParams?.orderBy === filter ? null : this.sortParams;
    delete this.appliedFilters?.[filter];
    delete this.showCheckIcon?.[filter];

    switch (filter) {
      case 'quantity':
        this.form.get('quantity').reset();
        delete this.filterParams?.['quantity'];
        break;
      case 'createdAt':
        this.form.get('startDate').reset();
        this.form.get('endDate').reset();
        delete this.filterParams?.['endDate'];
        delete this.filterParams?.['startDate'];
        break;
      case 'sku':
        this.form.get('sku').reset();
        delete this.filterParams?.['sku'];
        break;
      case 'levelName':
        this.form.get('levelName').reset();
        delete this.filterParams?.['levelName'];
        break;
      case 'section':
        this.form.get('section').reset();
        delete this.filterParams?.['section'];
        break;
      case 'position':
        this.form.get('position').reset();
        delete this.filterParams?.['x'];
        delete this.filterParams?.['y'];
        break;
      case 'alias':
        this.form.get('alias').reset();
        delete this.filterParams?.['alias'];
        break;
    }

    this.filterValues.emit({
      filterParams: this.filterParams,
      sortParams: this.sortParams
    });
    menu.closeMenu();
  }

  removeNullKeys(params) {
    Object.keys(params).forEach(key => {
      if (params[key] === null) {
        delete params[key];
      } else {
        this.setAppliedFilters(key);
      }
    });
  }

  setAppliedFilters(name: string) {
    switch (name) {
      case 'startDate':
      case 'endDate':
        this.appliedFilters['createdAt'] = true;
        break;
      case 'x':
      case 'y':
        this.appliedFilters['position'] = true;
        break;
      default:
        this.appliedFilters[name] = true;
        break;
    }
  }

  onEndDateChange() {
    this.maxDate = this.form.value.endDate._d;
  }

}
