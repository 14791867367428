// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'dev',
  // baseUrl: 'https://homolog-quantum-wms-7frbyaw24a-rj.a.run.app',
  baseUrl: 'https://dev-quantum-wms-7frbyaw24a-rj.a.run.app',
  v3BaseUrl: 'https://quantum-wms-api-dev-125236854453.southamerica-east1.run.app',
  //baseUrl: 'http://localhost:4001',
  production: false,
  tenantId: 'PDA',
  firebaseConfig: {
    apiKey: 'AAAAHSiyvrU:APA91bHkY28f0OkPtCp6ZxorvpB2T9sOY827FvgHoQ_Jq7eTQ3UzDkECQyO-rPU9Y5sNL0kySDgNBW4UPYYt586hWs3wq8gW_0ikFKE2KR5hSfK5C_WQuO1Sf65S_hOMi0WHgWsy95i-',
    authDomain: 'quantum-wms.firebaseapp.com',
    databaseURL: 'https://quantum-wms.firebaseio.com',
    projectId: 'quantum-wms',
    storageBucket: 'quantum-wms.appspot.com',
    messagingSenderId: '125236854453',
    appId: '1:125236854453:android:aafc356374aa73947188fc'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
