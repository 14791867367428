export interface IEventHistory {
  timestamp: number;
  updatedAt?: number;
  createdAt: number;
  id: string;
  tenantId: string;
  crateId: string;
  userId: string;
  type: EventType;
  x: number;
  y: number;
  z: number;
  deviceName: string;
  groundPositionAlias: string;
  groundPositionGroupLevelName: string;
  taskType: TaskType;
  user: unknown;
  previousSkuGroups: SkuGroups[];
  currentSkuGroups: SkuGroups[];
}

export interface SkuGroups {
  crateId?: string;
  sku: string;
  packSize: number;
  quantity: number;
  createdAt?: number;
  updatedAt?: number;
  name: string;
}

export enum EventType {
  FOUND = "FOUND",
	FOUND_MISPLACED = "FOUND_MISPLACED",
	MISSING = "MISSING",
	LOAD = "LOAD",
	UNLOAD = "UNLOAD",
	EXIT = "EXIT",
	ENTRY = "ENTRY",
	EDIT_PRODUCT = "EDIT_PRODUCT",
}

export enum TaskType {
  RECEIVING = "RECEIVING",
  DISPATCHING = "DISPATCHING",
  LOAD_DISPATCH = "LOAD_DISPATCH",
  RELOCATE_STORAGE = "RELOCATE_STORAGE",
  RELOCATE_POSITION = "RELOCATE_POSITION",
  RELOCATE_EXPEDITION = "RELOCATE_EXPEDITION",
  RELOCATE_DIVERGENT = "RELOCATE_DIVERGENT",
  RELOCATE_QUALITY = "RELOCATE_QUALITY",
  RELOCATE_DOCK = "RELOCATE_DOCK",
  RELOCATE_FREE = "RELOCATE_FREE",
  RELOCATE_FROM_PICKING = "RELOCATE_FROM_PICKING",
  INVENTORY_BY_GROUND_POSITION_GROUP = "INVENTORY_BY_GROUND_POSITION_GROUP",
  INVENTORY_BY_PRODUCT = "INVENTORY_BY_PRODUCT",
  INVENTORY_BY_TAG_RFID = "INVENTORY_BY_TAG_RFID",
  INVENTORY_BY_GROUND_POSITION = "INVENTORY_BY_GROUND_POSITION",
  PRODUCT_INVENTORY_BY_CRATE_TAG_RFID = "PRODUCT_INVENTORY_BY_CRATE_TAG_RFID",
  INVENTORY_BY_MISSING_CRATE = "INVENTORY_BY_MISSING_CRATE",
  PRODUCT_PICKING = "PRODUCT_PICKING",
  PICKING = "PICKING",
  EDIT_PALLET = "EDIT_PALLET",
  EXIT_BY_GROUND_POSITION = "EXIT_BY_GROUND_POSITION",
  EXIT_MISSING_PALLETS = "EXIT_MISSING_PALLETS",
  RECEIVING_INVOICE = "RECEIVING_INVOICE",
  RECEIVING_NO_INVOICE = "RECEIVING_NO_INVOICE",
  CREATE_CRATE = "CREATE_CRATE",
  TAKE_SOME_PRODUCTS_TO_EXIT = "TAKE_SOME_PRODUCTS_TO_EXIT",
}
