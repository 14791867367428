<app-header-modal [color]="'secondary-2'" title="Dados do Pallet">
  <div class="status-header" status>
    <app-status-header-modal [icon]="'check'" [color]="'success'" [status]="'Conferido'"></app-status-header-modal>
    <i class="uil uil-times" (click)="close()"></i>
  </div>
</app-header-modal>

<mat-tab-group mat-align-tabs="center" [selectedIndex]="selected.value"
               (selectedIndexChange)="onSelectedIndexChange($event)">

  <mat-tab label="Pallet">
    <app-pallet-details *ngIf="!data.isMissingPallet"
      [isExit]="data.isExit"
      (relocateClicked)="openSidenav()"
      [crateDetails]="data.itemDetails">
    </app-pallet-details>
    <app-missing-pallet-details *ngIf="data.isMissingPallet"
      (relocateClicked)="openSidenav()"
      [crate]="data.item">
    </app-missing-pallet-details>
  </mat-tab>
  <mat-tab label="Histórico">
    <app-movement-details [crateId]="data.item.id" *ngIf="loadEventTab" [warning]="exitPalletWarning"></app-movement-details>
  </mat-tab>
  <mat-tab label="Detalhes dos produtos">
    <app-product-image-tab [products]="products"></app-product-image-tab>
  </mat-tab>
  <mat-tab label="Comentários">
    <app-in-details-comments [itemDetails]="data.itemDetails || []"
                             (submitEvent)="submitComment($event)"
                             (deleteEvent)="deleteComment($event)"></app-in-details-comments>
  </mat-tab>
</mat-tab-group>
