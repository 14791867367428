import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ErrorService } from '../services/error.service';
import { ModulesEnum } from '../../../models/Modules';

export enum ActionsEnum {
  SET_RESOLUTION = 'SET_RESOLUTION',
  SET_RECEIVED_RESOLUTION = 'SET_RECEIVED_RESOLUTION',
  SET_DESTINATION = 'SET_DESTINATION',
  BULK_SET_DESTINATION = 'BULK_SET_DESTINATION',
  SEND_TASKS = 'SEND_TASKS',
  BULK_SEND_TASKS = 'BULK_SEND_TASKS',
  SEND_CRITICAL_TASKS = 'SEND_CRITICAL_TASKS',
  BULK_SEND_CRITICAL_TASKS = 'BULK_SEND_CRITICAL_TASKS',
  CHANGE_GROUND_POSITION_STATE = 'CHANGE_GROUND_POSITION_STATE',
  RESERVE_EXPEDITION = 'RESERVE_EXPEDITION',
  SET_INVOICE_REQUESTED = 'SET_INVOICE_REQUESTED',
  SEND_TO_EXPEDITION = 'SEND_TO_EXPEDITION',
  DELETE_TASKS = 'DELETE_TASKS',
  ADD_GROUND_GROUP = 'ADD_GROUND_GROUP',
  EDIT_GROUND_POSITION = 'EDIT_GROUND_POSITION',
  REMOVE_GROUND_GROUP_LEVEL = 'REMOVE_GROUND_GROUP_LEVEL',
  ADD_GROUND_GROUP_LEVEL = 'ADD_GROUND_GROUP_LEVEL',
  SET_TAGS_GROUND_GROUP = 'SET_TAGS_GROUND_GROUP',
  REMOVE_GROUND_GROUP = 'REMOVE_GROUND_GROUP',
  ENABLE_TENANT_LEVEL= 'ENABLE_TENANT_LEVEL',
  REMOVE_TENANT_LEVEL= 'REMOVE_TENANT_LEVEL',
  ADD_TENANT_LEVEL= 'ADD_TENANT_LEVEL',
  ENABLE_GROUND_GROUP_LEVEL= 'ENABLE_GROUND_GROUP_LEVEL',
  SET_TENANT_STATE= 'SET_TENANT_STATE',
  SET_TENANT_ERP= 'SET_TENANT_ERP',
  SET_TENANT_ERP_CONFIG= 'SET_TENANT_ERP_CONFIG',
  SET_TENANT_ORIENTATION= 'SET_TENANT_ORIENTATION',
  CREATE_EXPEDITION= 'CREATE_EXPEDITION',
  EDIT_USER_LOG_LEVEL= 'EDIT_USER_LOG_LEVEL',
  REMOVE_PALLET= 'REMOVE_PALLET',
  REMOVE_SHOWROOM_INVOICE= 'REMOVE_SHOWROOM_INVOICE',
  CONSUME_SHOWROOM_INVOICES= 'CONSUME_SHOWROOM_INVOICES',
  APPROVE_INVENTORY= 'APPROVE_INVENTORY',
  REJECT_INVENTORY= 'REJECT_INVENTORY',
  REMOVE_PRODUCT_FROM_PICKING_POSITION = 'REMOVE_PRODUCT_FROM_PICKING_POSITION',
  SET_PRODUCT_TO_PICKING_POSITION = 'SET_PRODUCT_TO_PICKING_POSITION',
  APPROVE_PALLET_STOCK_POSITION = 'APPROVE_PALLET_STOCK_POSITION',
  SET_EXECUTION_PLAN = 'SET_EXECUTION_PLAN',
  UNSET_EXECUTION_PLAN = 'UNSET_EXECUTION_PLAN',
  START_PACKING = 'START_PACKING',
  RUN_EXECUTION_PLAN = 'RUN_EXECUTION_PLAN',
  CREATE_INTERNAL_OUT_ORDER = 'CREATE_INTERNAL_OUT_ORDER',
  CREATE_PRODUCT = 'CREATE_PRODUCT',
  UPDATE_PRODUCT_PICTURE = 'UPDATE_PRODUCT_PICTURE',
  SET_TENANT_MODULES = 'SET_TENANT_MODULES',
  SET_TENANT_CONFIG = 'SET_TENANT_CONFIG',
  EDIT_PRODUCT = 'EDIT_PRODUCT',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT',
  EDIT_USER = 'EDIT_USER',
  DELETE_USER = 'DELETE_USER',
  CREATE_USER = 'CREATE_USER',
  EXIT_BY_GROUND_POSITION = 'EXIT_BY_GROUND_POSITION',
  UPDATE_GROUND_POSITION_ALIAS = "UPDATE_GROUND_POSITION_ALIAS"
}

@Injectable({
  providedIn: 'root'
})
export class ActionDataSourceService {

  constructor(

    private httpClient: HttpClient,
    private errorService: ErrorService
    ) { }

  action(action: ActionsEnum,
         params: any,
         options: any = {disableAutoErrorHandler: false},
         module: ModulesEnum = ModulesEnum.ADMIN_DESKTOP
  ) {
    const headers = {
      'Content-Type': 'application/json'
    };
    const body = {
      type: action,
      data: params
    };
    return this.httpClient.post(environment.baseUrl + `/api/v1/${module}/actions`, body, { headers }).toPromise()
      .catch(this.errorHandler(options));
  }

  actionHandScanner(action: ActionsEnum, params, options:any={disableAutoErrorHandler: false}) {
    const headers = {
      'Content-Type': 'application/json'
    };
    const body = {
      type: action,
      data: params
    };
    return this.httpClient.post(environment.baseUrl + '/api/v1/hand-scanner/actions', body, { headers }).toPromise()
      .catch(this.errorHandler(options));
  }

  errorHandler(options){
    const {disableAutoErrorHandler} = options;
    return (err) => {
      if(!disableAutoErrorHandler){
        this.errorService.openErrorSnackBar(err);
      }
      throw err;
    }
  }
}
