<app-unknown-address-table-header
  (filterValues)="onFiltersApply($event)"
  (onSelectAll)="selectAll($event)"
  [allSelected]="isAllSelected"
  >
</app-unknown-address-table-header>
<app-table-items>
  <app-table-item *ngFor="let crate of crates; let odd = odd" [isOdd]="odd">
    <app-table-column name="checkbox-btn" size="micro">
      <mat-checkbox content (change)="onCheckboxClick(crate)" [checked]="crate.selected"></mat-checkbox>
    </app-table-column>

    <app-table-column name="createdAt" size="small" (click)="itemClicked(crate)">
      <span content>{{formatDate(crate.createdAt)}}</span>
    </app-table-column>

    <app-table-column name="lastMovedAt" size="small" (click)="itemClicked(crate)">
      <span content>{{formatDate(crate.lastMovedAt)}}</span>
    </app-table-column>

    <app-table-column name="section" size="small" (click)="itemClicked(crate)">
      <span content>{{crate.section || '---'}}</span>
    </app-table-column>

    <app-table-column name="alias" size="smaller" (click)="itemClicked(crate)">
      <span content>{{crate.alias || '---'}}</span>
    </app-table-column>

    <app-table-column name="tagRfid" size="small" (click)="itemClicked(crate)">
      <span content class="ellipsis" matTooltip="{{crate.tagRfid}}">{{crate.tagRfid}}</span>
    </app-table-column>

    <app-table-column name="product-code" size="small" (click)="itemClicked(crate)">
      <span content>
        <span *ngFor="let product of crate.skuGroups">{{ product.sku }}</span>
      </span>
    </app-table-column>

    <app-table-column name="description" size="medium" (click)="itemClicked(crate)">
      <span content>
        <span *ngFor="let product of crate.skuGroups">{{ product.name }}</span>
      </span>
    </app-table-column>

    <app-table-column name="quantity" size="qtd" alignRight="true" (click)="itemClicked(crate)">
      <span content>
        <span *ngFor="let product of crate.skuGroups">{{ product.quantity * product.packSize }}</span>
      </span>
    </app-table-column>

    <app-table-column name="activeInventoryCount" size="small" (click)="itemClicked(crate)" alignRight="true">
      <div class="status-option" content *ngIf="crate.activeInventoryCount > 0">
        <div class="status-icon online"></div>
        Ativo
      </div>
      <div class="status-option" content *ngIf="crate.activeInventoryCount <= 0">
        <div class="status-icon"></div>
        Inativo
      </div>
    </app-table-column>

    <app-table-column name="total" size="small" alignRight="true" (click)="itemClicked(crate)">
      <span content>{{crate.inventoryCount}}</span>
    </app-table-column>
  </app-table-item>
</app-table-items>