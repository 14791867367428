<ng-container [formGroup]="form">
  <app-table-header-column name="checkbox-btn" size="micro" matTooltip="Selecionar todos desta página"
    (click)="selectAll()">
    <span content>
      <mat-checkbox (click)="$event.preventDefault()" [checked]="allSelected"></mat-checkbox>
    </span>
  </app-table-header-column>

  <app-table-header-column name="createdAt" size="small">
    <span content>
      Entrada
      <app-filter-button [column]="'createdAt'" [menu]="createdAtMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #createdAtMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por data de entrada</h4>
      <button (click)="sort('createdAt', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('createdAt', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <mat-label>Data inicial</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" required [max]="maxDate" disabled>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Data final</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="endDate"
          required [max]="maxDate" disabled (dateChange)="onEndDateChange()"
        >
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()"
          [disabled]="!showCheckIcon['createdAt'] && (form.get('startDate').invalid || form.get('endDate').invalid)">
          Salvar
        </button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="lastMovedAt" size="small">
    <span content>
      Últ movimentação
      <app-filter-button [column]="'lastMovedAt'" [menu]="lastMovedAtMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #lastMovedAtMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Ordernar por data da última movimentação</h4>
      <button (click)="sort('lastMovedAt', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['lastMovedAt'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('lastMovedAt', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['lastMovedAt'] && sortDirection == 'ASC'"></i>
      </button>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="section" size="small">
    <span content>
      Últ Setor
      <app-filter-button [column]="'section'" [menu]="sectionFilterMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>
  
  <mat-menu #sectionFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por último setor</h4>
      <button (click)="sort('section', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['section'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('section', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['section'] && sortDirection == 'ASC'"></i>
      </button>
        <mat-form-field appearance="outline">
          <input matInput
                 placeholder="Último setor"
                 formControlName="section"
                 autocomplete="off">
        </mat-form-field>
        <section class="actions">
          <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
          <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
        </section>
    </div>
  </mat-menu>

  <app-table-header-column name="alias" size="smaller">
    <span content>
      Últ Posição
      <app-filter-button [column]="'alias'" [menu]="aliasFilterMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>
  
  <mat-menu #aliasFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por última posição</h4>
      <button (click)="sort('alias', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['alias'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('alias', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['alias'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <input matInput
               placeholder="Última posição"
               formControlName="alias"
               autocomplete="off">
        <mat-error>Posição inválida</mat-error>
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="tagRfid" size="small">
    <span content>
      Tag Rfid
      <app-filter-button [column]="'tagRfid'" [menu]="tagRfidMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #tagRfidMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por Tag Rfid</h4>
      <button (click)="sort('tagRfid', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['tagRfid'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('tagRfid', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['tagRfid'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Tag Rfid" formControlName="tagRfid" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="product-code" size="small">
    <span content>
      Código do produto
      <app-filter-button [column]="'sku'" [menu]="skuMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #skuMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por código do produto</h4>
      <button (click)="sort('sku', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['sku'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('sku', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['sku'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Código do produto" formControlName="sku" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="description" size="medium">
    <span content>Descrição</span>
  </app-table-header-column>

  <app-table-header-column name="quantity" size="qtd" alignRight="true">
    <span content>
      Qtd
      <app-filter-button [column]="'quantity'" [menu]="quantityFilterMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #quantityFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por quantidade</h4>
      <button (click)="sort('quantity', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['quantity'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('quantity', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['quantity'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Quantidade" type="number" formControlName="quantity" min="1" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="activeInventoryCount" size="small" alignRight="true">
    <span content>
      Inventário ativo
      <app-filter-button [column]="'activeInventoryCount'" [menu]="activeInventoryCountMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #activeInventoryCountMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por inventário ativo</h4>
      <button (click)="sort('activeInventoryCount', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['activeInventoryCount'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('activeInventoryCount', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['activeInventoryCount'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <mat-select placeholder="Tipo de inventário" formControlName="activeInventoryType" (selectionChange)="onActiveInventoryChange($event)">
          <mat-option [value]="1">
            <div class="status-option">
              <div class="status-icon online"></div>
              Ativo
            </div>
          </mat-option>
          <mat-option [value]="0">
            <div class="status-option">
              <div class="status-icon"></div>
              Inativo
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="inventoryCount" size="small" alignRight="true">
    <span content>
      Total de inventários
      <app-filter-button [column]="'inventoryCount'" [menu]="inventoryCountFilterMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #inventoryCountFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por total de inventários</h4>
      <button (click)="sort('inventoryCount', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['inventoryCount'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('inventoryCount', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['inventoryCount'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <input matInput type="number" placeholder="Total de inventários" min="0" formControlName="inventoryCount" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

</ng-container>