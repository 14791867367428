import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IReport } from '../../../models/Report';
import * as moment from 'moment/moment';
import { MatDialog } from '@angular/material/dialog';
import { ReportDetailsModalComponent } from '../../modals/report-details-modal/report-details-modal.component';
import { ActionService, InventoryReviewAction } from '../../core/services/action.service';
import { ActionsEnum } from '../../core/data-sources/action-data-source.service';
import { ErrorService } from '../../core/services/error.service';
import { ReportsFilterParams, ReportsService } from 'src/app/core/services/reports.service';
import { StockSortParams } from 'src/app/core/services/stock.service';
import { Subscription } from 'rxjs';
import { MAT_DATE_FORMATS } from '@angular/material/core';

const DEFAULT_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [{provide: MAT_DATE_FORMATS, useValue: DEFAULT_DATE_FORMAT}]
})
export class ReportsComponent implements OnInit, OnDestroy {
  reports: IReport[];
  actualHour = moment().format('HH:mm');
  isLoading = false;
  appliedFilters = {};
  filterParams: ReportsFilterParams;
  sortParams: StockSortParams = {
    orderBy: 'createdAt',
    sortBy: 'DESC'
  };
  itemsSubscription: Subscription;

  constructor(private router: Router,
    private dialog: MatDialog,
    private actionService: ActionService,
    private errorService: ErrorService,
    public reportService: ReportsService
  ) { }

  ngOnDestroy(): void {
    this.itemsSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.reportService.init(this.filterParams, this.sortParams).catch(err => console.error);
    this.itemsSubscription = this.reportService.items$.subscribe((items) => {
      this.reports = items;
      this.isLoading = false;
    });
  }

  async getReports(): Promise<void> {
    try {
      this.isLoading = true;
      this.reportService.reload(this.filterParams, this.sortParams);
      this.isLoading = false;
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
      this.isLoading = false;
    }
  }

  itemClicked(report: IReport) {
    const dialogRef = this.dialog.open(ReportDetailsModalComponent, {
      panelClass: 'details-modal',
      data: report,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((res: { state: 'CANCELED' | 'REJECTED' | 'APPROVED', inventoryId: string, tagsRfidToRemove: string[] }) => {
      if (!res) {
        return;
      }

      const data: InventoryReviewAction = {
        type: ActionsEnum.APPROVE_INVENTORY,
        data: {
          inventoryId: res.inventoryId
        }
      };
      switch (res.state) {
        case 'CANCELED':
          return;
        case 'APPROVED':
          data.type = ActionsEnum.APPROVE_INVENTORY;
          if (res?.tagsRfidToRemove?.length) {
            data.data.inventoryData = {
              tagsRfidToRemove: res.tagsRfidToRemove
            };
          }
          this.actionService.inventoryReview(data).then(() => {
            this.update();
          });
          break;
        case 'REJECTED':
          data.type = ActionsEnum.REJECT_INVENTORY;
          this.actionService.inventoryReview(data).then(() => {
            this.update();
          });
          break;
      }
    });
  }

  toStock() {
    this.router.navigate(['stock']);
  }

  update() {
    this.getReports();
    this.actualHour = moment().format('HH:mm');
  }

  onFilterValuesChange(params: { sortParams: StockSortParams, filterParams: ReportsFilterParams }) {
    this.reportService.currentPage = 1;
    this.filterParams = params?.filterParams;
    this.sortParams = this.setSortParams(params?.sortParams);
    this.update();
  }

  setSortParams(sort: StockSortParams) {
    if (!sort || !sort.sortBy) {
      return;
    }
    return sort;
  }

  onNextPage(event) {
    if (event) this.reportService.getNextPage(this.filterParams, this.sortParams);
  }

  onPreviousPage(event) {
    if (event) this.reportService.getPreviousPage(this.filterParams, this.sortParams);
  }

}
