<table mat-table [dataSource]="dataSource" class="table">
  <ng-container *ngFor="let column of columnsDefinition" [matColumnDef]="column.key">
    <th mat-header-cell class="header-cell" [ngClass]="{'align-right': column.alignRight}" *matHeaderCellDef>{{column.text}}</th>
    <td mat-cell *matCellDef="let element" [ngClass]="{'align-right': column.alignRight}"
      matTooltip="{{column.showTooltip ? element[column.key] : null}}"
      matTooltipClass="tooltip">
      {{element[column.key]}}
    </td>
  </ng-container>

  <ng-container *ngIf="hasActions" matColumnDef="actions">
    <th mat-header-cell class="header-cell action" *matHeaderCellDef></th>
    <td mat-cell class="action-cell" *matCellDef="let element">
      <button class="remove-btn" (click)="remove(element)">
        <i class="uil uil-trash"></i>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row class="header" *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row class="row" *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div *ngIf="!dataSource?.length" class="no-data">
  {{emptyDataMessage}}
</div>