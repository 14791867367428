import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-unknown-address-table-header',
  templateUrl: './unknown-address-table-header.component.html',
  styleUrls: ['./unknown-address-table-header.component.scss']
})
export class UnknownAddressTableHeaderComponent implements OnInit {
  @Output() filterValues = new EventEmitter();
  @Output() onSelectAll = new EventEmitter(false);
  @Input() allSelected: boolean;
  showCheckIcon = {};
  sortDirection: "ASC" | "DESC";
  sortParams = {};
  appliedFilters = {};
  maxDate = new Date();
  form = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    lastMovedAt: new FormControl(null),
    sku: new FormControl(null),
    tagRfid: new FormControl(null),
    minActiveInventoryCount: new FormControl(null),
    maxActiveInventoryCount: new FormControl(null),
    activeInventoryType: new FormControl(null),
    quantity: new FormControl(null),
    inventoryCount: new FormControl(null),
    section: new FormControl(null),
    alias: new FormControl(null)
  });

  constructor() { }

  ngOnInit(): void {
  }

  sort(orderBy: string, sortBy: "ASC" | "DESC") {
    this.sortDirection = sortBy;
    this.showCheckIcon[orderBy] = true;
    this.sortParams = { orderBy, sortBy };
  }

  clearFilters() {
    this.appliedFilters = {};
    this.showCheckIcon = {};
    this.sortDirection = null;
    this.sortParams = null;
    this.form.reset();
    this.maxDate = new Date();
    this.filterValues.emit();
  }

  applyFilters() {
    const startDate = this.form.value.startDate ? moment(this.form.value.startDate._d).valueOf() : null;
    const endDate = this.form.value.endDate ? moment(this.form.value.endDate._d).endOf('day').valueOf() : null;
        
    const params = {
      startDate: startDate,
      endDate: endDate,
      quantity: this.form.value.quantity,
      inventoryCount: this.form.value.inventoryCount,
      maxActiveInventoryCount: this.form.value.maxActiveInventoryCount,
      minActiveInventoryCount: this.form.value.minActiveInventoryCount,
      sku: this.form.value.sku,
      tagRfid: this.form.value.tagRfid,
      alias: this.form.value.alias,
      section: this.form.value.section
    };

    this.removeNullKeys(params);
    this.filterValues.emit({
      filterParams: params,
      sortParams: this.sortParams
    });
  }

  removeNullKeys(params) {
    Object.keys(params).forEach(key => {
      if (params[key] === null) {
        delete params[key];
      } else {
        this.setAppliedFilters(key);
      }
    });
  }

  setAppliedFilters(name) {
    switch (name) {
      case 'startDate':
      case 'endDate':
        this.appliedFilters['createdAt'] = true;
        break;
      case 'minActiveInventoryCount':
      case 'maxActiveInventoryCount':
        this.appliedFilters['activeInventoryCount'] = true;
      default:
        this.appliedFilters[name] = true;
        break;
    }
  }

  selectAll() {
    this.onSelectAll.emit(!this.allSelected);
  }

  onActiveInventoryChange(event) {
    const selectedValue = event.value;

    if (selectedValue === 1) {
      this.form.patchValue({
        minActiveInventoryCount: 1,
        maxActiveInventoryCount: null
      });
    } else if (selectedValue === 0) {
      this.form.patchValue({
        minActiveInventoryCount: null,
        maxActiveInventoryCount: 0
      });
    }
  }

  onEndDateChange() {
    this.maxDate = this.form.value.endDate._d;
  }

}
