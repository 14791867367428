import { getGroundPositions } from 'src/app/helpers/get-ground-positions';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ICrateData,
  IReport,
  translateType
} from '../../../models/Report';
import formatDate from '../../helpers/format-date';
import { translateState } from '../../../models/Report';
import { ActionService, InventoryTypesEnum } from '../../core/services/action.service';
import { StockDataSourceService } from 'src/app/core/data-sources/stock-data-source.service';
import { toExcelFormat } from 'src/app/helpers/format-position';
import getName from 'src/app/helpers/format-name';

interface GroundPosition {
  name: string
  groupId: string
}
@Component({
  selector: 'app-report-details-modal',
  templateUrl: './report-details-modal.component.html',
  styleUrls: ['./report-details-modal.component.scss']
})
export class ReportDetailsModalComponent implements OnInit {
  toExcelFormat = toExcelFormat;
  formatDate = formatDate;
  translateState = translateState;
  inventoryTypesEnum = InventoryTypesEnum;
  items: ICrateData[] = [];
  reportData;
  totalItems: number;
  productName = '';
  groundPositions: GroundPosition[] = [];
  groundPositionsNames: string[] = [];
  formattedGroundPositionName = '';
  mapOptions;
  removePallets = false;
  palletsToRemove = [];
  displayedColumns = ['rfid', 'position', 'sku', 'name', 'quantity'];

  constructor(
    public dialogRef: MatDialogRef<ReportDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public report: IReport,
    private stockDataSourceService: StockDataSourceService,
    private actionService: ActionService
  ) { }

  async ngOnInit() {
    // TODO: refactor component to use getInventoryById to fetch report data instead of receiving it by dialog data
    this.handleReportTypes();
    this.setGroundPositionName();
    this.setProductName();
  }

  async setGroundPositionName() {
    if (this.reportData.type === InventoryTypesEnum.INVENTORY_BY_GROUND_POSITION_GROUP) {
      this.mapOptions = await this.stockDataSourceService.getGroundPosition(true);
      this.groundPositions = getGroundPositions(this.mapOptions);
      this.groundPositions.map(position => {
        this.reportData?.target?.groundPositionGroupIds?.forEach(groupId => {
          if (position.groupId === groupId) {
            this.groundPositionsNames.push(position.name);
            this.formattedGroundPositionName = this.groundPositionsNames.join(' / ');
          }
        })
      });
    }
  }

  setProductName() {
    if (this.reportData.type === InventoryTypesEnum.INVENTORY_BY_PRODUCT) {
      this.items.map(item => {
        item.skuGroups.forEach(product => {
          if (product.sku === this.reportData?.target?.skus?.[0]) {
            this.productName = product.name;
          }
        });
      });
    }
  }

  handleReportTypes(): void {
    this.reportData = this.report.data;
    this.mapDataType();
    switch (this.report.data.type) {
      // TODO: refactor this switch case
      case InventoryTypesEnum.PRODUCT_INVENTORY_BY_CRATE_TAG_RFID:
        this.items = this.reportData?.skuGroup?.found?.concat(this.reportData?.skuGroup?.extra).concat(this.reportData?.skuGroup?.missing);
        this.totalItems = this.report.total?.items
          || this.reportData?.skuGroup?.found?.length + this.reportData?.skuGroup?.extra?.length
          + this.reportData?.skuGroup?.missing?.length || '---';
        break;
      default:
        this.items = this.reportData?.found?.concat(this.reportData?.missing).concat(this.reportData?.foundMisplaced)
          || this.reportData?.FOUND?.concat(this.reportData?.MISSING).concat(this.reportData?.FOUND_MISPLACED);
        this.totalItems = this.report.total?.items
          || this.reportData?.found?.length + this.reportData?.foundMisplaced?.length + this.reportData?.missing?.length
          || this.reportData?.FOUND?.length + this.reportData?.FOUND_MISPLACED?.length + this.reportData?.MISSING?.length
          || '---';
        break;
    }

    this.items.forEach(item => {
      item.removePallet = false;
    });
  }

  mapDataType() {
    const possibleArrayName = [
      'found',
      'FOUND',
      'foundMisplaced',
      'FOUND_MISPLACED',
      'missing',
      'MISSING'
    ];

    let targetArray: any[];

    for (const name of possibleArrayName) {
      if (name in this.reportData) {
        targetArray = this.reportData[name];
        for (const element of targetArray) {
          Object.assign(element, {[name]: true});
        }
      }
    }
  }

  close(state: 'APPROVED' | 'REJECTED' | 'CANCELED'): void {
    this.palletsToRemove = this.items.filter(item => item.removePallet);
    if (this.palletsToRemove.length && state === 'APPROVED') {
      this.removePallets = true;
      return;
    }
    this.dialogRef.close({ state, inventoryId: this.report.id });
  }

  confirmRemovePallets() {
    const tagsRfidToRemove = this.palletsToRemove.map(pallet => pallet.tagRfid);
    this.dialogRef.close({
      state: "APPROVED", 
      inventoryId: this.report.id,
      tagsRfidToRemove
    });
  }

  getUserName(user) {
    return getName(user);
  }

  translateTypeName(type: InventoryTypesEnum) {
    return translateType(type);
  }

}
