import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddOrEditProductModalComponent } from '../../modals/add-or-edit-product-modal/add-or-edit-product-modal.component';
import * as moment from 'moment/moment';
import { first } from 'rxjs/operators';
import { ProductManagementService } from '../../core/services/product-management.service';
import { Product, ProductManagementFiltersParams } from '../../../models/Product';
import { Subscription } from 'rxjs';
import { ErrorsEnum, ErrorService } from '../../core/services/error.service';
import { ActionService } from '../../core/services/action.service';
import { ActionsEnum } from '../../core/data-sources/action-data-source.service';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { ProductDetailsModalComponent } from '../../modals/product-details-modal/product-details-modal.component';
import { ProductManagementDataSourceService } from '../../core/data-sources/product-management-data-source.service';
import { PaginationService } from 'src/app/core/services/pagination.service';
import { StockSortParams } from 'src/app/core/services/stock.service';

@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.scss']
})
export class ProductManagementComponent implements OnInit, OnDestroy {

  actualHour;
  filtersApplied: ProductManagementFiltersParams = {};
  products: Product[] = [];
  isLoading = false;
  fullPageLoading = false;
  productsSubscription: Subscription;
  sortParams: StockSortParams = {
    orderBy: 'createdAt',
    sortBy: 'DESC'
  };

  constructor(private router: Router,
              private dialog: MatDialog,
              private productManagementService: ProductManagementService,
              private productManagementDataSource: ProductManagementDataSourceService,
              private errorService: ErrorService,
              private actionService: ActionService,
              public paginationService: PaginationService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.productManagementService.init(this.filtersApplied, this.sortParams).catch(err => {
      if (err.error.type === ErrorsEnum.PRODUCTS_FORBIDDEN_ACCESS) {
        this.router.navigate(['/dashboard']);
        this.errorService.openErrorSnackBarWithCustomMessage('Altere a opção de gerenciar produtos no setup para ter acesso a esta tela.');
      }
      console.error(err);
    });
    this.productsSubscription = this.paginationService.items$.subscribe(items => {
      this.actualHour = moment().format('HH:mm');
      this.products = items;
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.productsSubscription?.unsubscribe();
  }

  openAddProductModal(): void {
    const dialogRef = this.dialog.open(AddOrEditProductModalComponent, {
      panelClass: 'details-modal',
      autoFocus: false,
      data: {
        modalType: 'add'
      }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(async (response) => {
      if (response) {
        await this.update();
      }
    });
  }

  async openDetailsModal(product): Promise<void> {
    try {
      this.fullPageLoading = true;
      const productDetails = await this.productManagementDataSource.getProduct(product.sku);
      const dialogRef = this.dialog.open(ProductDetailsModalComponent, {
        panelClass: 'details-modal',
        autoFocus: false,
        data: productDetails
      });
      dialogRef.afterClosed().pipe(first()).subscribe(async (response) => {
        if (response) {
          await this.update();
        }
      });
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    } finally {
      this.fullPageLoading = false;
    }

  }

  deleteProduct(product): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      autoFocus: false,
      panelClass: 'confirm-modal',
      data: { label: `Deseja deletar o produto ${product.name || product.sku}?` }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(async (res) => {
      if (res) {
        try {
          this.isLoading = true;
          await this.actionService.removeProduct({
            type: ActionsEnum.REMOVE_PRODUCT,
            data: {
              sku: product.sku
            }
          });
          await this.update();
        } catch (e) {
          this.errorService.openErrorSnackBar(e);
        } finally {
          this.isLoading = false;
        }
      }
    });
  }

  async update() {
    try {
      this.isLoading = true;
      await this.productManagementService.reload(this.filtersApplied, this.sortParams);
      this.actualHour = moment().format('HH:mm');
    } catch (e) {
      this.errorService.openErrorSnackBar(e);
    } finally {
      this.isLoading = false;
    }
  }

  toDashboard(): void {
    this.router.navigate(['in']);
  }

  filterData(params: { sortParams: StockSortParams, filterParams: ProductManagementFiltersParams }) {
    this.paginationService.currentPage = 1;
    this.filtersApplied = params?.filterParams;
    this.sortParams = this.setSortParams(params?.sortParams);
    this.update();
  }

  setSortParams(sort) {
    if (!sort || !sort.sortBy) {
      return;
    }
    return sort;
  }
}
